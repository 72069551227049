import { FC, useEffect } from 'react'

import { useInView } from 'react-intersection-observer'

import { GamesSlider } from '@/widgets/games'

import { useGameList } from '@/features/games/games-list/api/useGameList'

import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { Loader } from '@/shared/ui/loader'

import styles from './GameList.module.scss'
import { CardSlot } from './ui'

export const GameList: FC = () => {
  const { getFieldStatic } = useTranslationField()
  const { isMobile } = useIsMobile()
  const { ref, inView } = useInView({
    threshold: 0
  })
  const { games, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useGameList(12)

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  })

  return (
    <div className='GameBlock'>
      <div className={styles.title}>
        {getFieldStatic('header_menu_game')}
        {isMobile && <div className={styles.more}>{getFieldStatic('show_more')}</div>}
      </div>
      <GamesSlider />
      <div className={styles.slotsBlock}>
        {games?.length ? (
          <>{games?.map(item => <CardSlot key={item.id} {...item} />)}</>
        ) : !isLoading && !games?.length ? (
          <>
            {getFieldStatic('not_found')}
            {getFieldStatic('not_found')}
          </>
        ) : null}
      </div>
      {isLoading && <Loader />}
      {!isLoading && isFetchingNextPage && <Loader isMini />}
      <div ref={ref} />
    </div>
  )
}
