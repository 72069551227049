import { FC } from 'react'

import { useTranslationField } from '@/shared/hooks'
import { reFormatNum } from '@/shared/utils'

import styles from './balanceBlock.module.scss'

interface IProps {
  balance?: number
}
export const BalanceBlock: FC<IProps> = ({ balance }) => {
  const { getFieldStatic } = useTranslationField()
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{getFieldStatic('balance')}</div>₿ {reFormatNum(balance)}
    </div>
  )
}
