import React, { FC } from 'react'

import clsx from 'clsx'

import styles from './BettingText.module.scss'

interface IProps {
  status:
    | 'won'
    | 'loss'
    | 'none'
    | 'progress'
    | 'refund'
    | 'refund_error'
    | 'win_half_1'
    | 'win_half_2'
    | 'lose_half_1'
    | 'lose_half_2'

  name: string
}
export const BettingStatusText: FC<IProps> = ({ name, status }) => {
  return <div className={clsx(styles.wrapper, styles[status])}>{name}</div>
}
