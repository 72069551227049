import React, { Dispatch, FC } from 'react'

import { useLocation } from 'react-router-dom'
import DesktopBetsBasket from 'widgets/basket/bets-basket'

import { useProfileData } from '@/features/profile/api/useProfileData'

import { BasketJSX, BlogJSX, ExpertsJSX, GamesJSX, SportIconJSX } from '@/shared/assets/header/svg'
import { useTranslationField } from '@/shared/hooks'

import { MenuElement } from '../menu-element'

import styles from './MobileHeader.module.scss'

interface IProps {
  setShowMenu: Dispatch<React.SetStateAction<boolean | undefined>>
}

export const MobileHeaderBottom: FC<IProps> = ({ setShowMenu }) => {
  const { userData } = useProfileData()
  const { getFieldStatic } = useTranslationField()

  const { pathname } = useLocation()

  const menuList = [
    {
      isView: true,
      url: '/sports',
      title: getFieldStatic('sport_route'),
      image: <SportIconJSX />,
      active: 'sports',
      isMain: false,
      onClick: null
    },
    {
      isView: true,
      url: '/games',
      title: getFieldStatic('games_route'),
      image: <GamesJSX />,
      active: 'games',
      isMain: false,
      onClick: null
    },
    {
      isView: userData,
      title: getFieldStatic('basket'),
      component: <DesktopBetsBasket />,
      image: <BasketJSX />,
      active: null,
      isMain: false,
      onClick: () => setShowMenu(false)
    },
    {
      isView: true,
      url: '/blog',
      title: getFieldStatic('blog_route'),
      image: <BlogJSX />,
      active: 'blog',
      isMain: false,
      onClick: null
    },
    {
      isView: true,
      url: '/experts',
      title: getFieldStatic('experts_route'),
      image: <ExpertsJSX />,
      active: 'experts',
      isMain: false,
      onClick: null
    }
  ]

  return (
    <>
      <div className={styles.wrapperHeader}>
        <div className={styles.wrapper}>
          {menuList.map((item, index) => {
            if (!item.isView) return null
            return (
              <MenuElement
                key={index}
                url={item.url}
                title={item.title}
                srcImage={item.image}
                component={item.component}
                active={pathname.includes(item.active!)}
                isMain={item.isMain}
                onClick={item.onClick ? item.onClick : () => {}}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}
