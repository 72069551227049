import React, { FC } from 'react'

import clsx from 'clsx'

import { useStatusBet } from '@/features/profile/ui/betting-history-table/hooks'

import { BettingStatusText } from '@/entities/profile'

import { useTranslationField } from '@/shared/hooks'
import { ISport } from '@/shared/types/sport.types'
import { timeFormatter } from '@/shared/utils'

import styles from './MoreInfo.module.scss'

interface IProps {
  data: ISport.IExpressList | ISport.IBet
  isOpen?: boolean
}
export const MoreInfo: FC<IProps> = ({ data, isOpen }) => {
  const { getBetMessage, getBetMessageExpress, getBetStatus, getStatus } = useStatusBet()
  const { getFieldStatic, getField } = useTranslationField()

  const isChildrenInExpress = !('game_list' in data)

  const status = isChildrenInExpress ? getBetStatus(data) : getStatus(data)
  const name = isChildrenInExpress ? getBetMessageExpress(data) : getBetMessage(data)

  return (
    <div className={clsx(styles.moreInfo, isOpen && styles.open)}>
      <div>
        <div className={styles.moreWrapper}>
          <div className={styles.row}>
            <div className={styles.columnName}>{getFieldStatic('id')}</div>
            <div className={styles.value}>
              <span>№{data.id}</span>
              <span className={styles.grayText}>
                {isChildrenInExpress
                  ? timeFormatter(data?.start_date)
                  : timeFormatter(data?.createdAt)}
              </span>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnName}>{getFieldStatic('name')}</div>
            <div className={styles.value}>
              <span>
                {isChildrenInExpress
                  ? getField(data, 'tournament_name')
                  : getField(data.game_list, 'tournament_name')}
              </span>
              <span className={styles.grayText}>
                {isChildrenInExpress
                  ? `${getField(data, 'opponent_one_name')} - ${getField(
                      data,
                      'opponent_two_name'
                    )}`
                  : `${getField(data.game_list, 'opponent_one_name')} - ${getField(
                      data.game_list,
                      'opponent_two_name'
                    )}`}
              </span>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnName}>{getFieldStatic('summ')}</div>
            <div className={styles.value}>
              <span>{isChildrenInExpress ? getFieldStatic('result') : getFieldStatic('bet')}</span>
              <span className={styles.grayText}>
                {isChildrenInExpress ? data.score : `${data.amount} ₿`}
              </span>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnName}>{getFieldStatic('winnings')}</div>
            <div className={styles.value}>
              <span>
                {isChildrenInExpress
                  ? getFieldStatic('event')
                  : getFieldStatic('possible_winnings')}
              </span>
              <span className={styles.grayText}>
                {isChildrenInExpress ? getField(data.markets, 'name') : data.possible_win}₿
              </span>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnName}>{getFieldStatic('status')}</div>
            <div className={styles.value}>
              <span> {getFieldStatic('status')}</span>
              <div className={styles.grayText}>
                <BettingStatusText status={status} name={name} />
              </div>
            </div>
          </div>
          {!isChildrenInExpress && (
            <div className={styles.row}>
              <div className={styles.columnName}>{getFieldStatic('bet_types')}</div>
              <div className={styles.value}>
                <span> {getFieldStatic('bet_types')}</span>
                <span className={styles.grayText}>{getFieldStatic(data.type_of_bet)}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
