import { FC } from 'react'

import { clsx } from 'clsx'

import { useTranslationField } from '@/shared/hooks'

import styles from './CouponTabs.module.scss'
import { ICouponTabs } from './coupon-tabs.interface'

// TODO добавить , 'accumulator' , 'system_bet'
const tabNames = ['accumulator', 'singles']
const historyTabNames = ['active', 'calculated']

const CouponTabs: FC<ICouponTabs> = ({ activeTab, setActiveTab, isHistory }) => {
  const onClickTab = (index: number) => setActiveTab(index)
  const { getFieldStatic } = useTranslationField()

  return (
    <div>
      <div className={styles.wrapper}>
        {isHistory ? (
          <>
            {historyTabNames.map((tabName, index) => (
              <div
                key={index}
                onClick={() => onClickTab(index)}
                className={clsx(styles.element, activeTab === index && styles.active)}>
                {getFieldStatic(tabName as any)}
              </div>
            ))}
          </>
        ) : (
          <>
            {tabNames.map((tabName, index) => (
              <div
                key={index}
                onClick={() => onClickTab(index)}
                className={clsx(styles.element, activeTab === index && styles.active)}>
                {getFieldStatic(tabName as any)}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default CouponTabs
