import { FC } from 'react'

import { useTranslationField } from '@/shared/hooks'

import styles from './LayoutHeader.module.scss'
import { messages } from '@/i18n/messages'

interface IProps {
  title?: keyof typeof messages.en
  titleCustom?: string
  description?: keyof typeof messages.en
}

export const LayoutHeader: FC<IProps> = ({ title, description, titleCustom }) => {
  const { getFieldStatic } = useTranslationField()

  return (
    <div className={styles.header}>
      <div className={styles.title}>{title ? getFieldStatic(title) : titleCustom}</div>
      <div className={styles.description}>{description ? getFieldStatic(description) : ''}</div>
    </div>
  )
}
