import { PropsWithChildren, useEffect, useState } from 'react'

import Cookies from 'js-cookie'

import '@/app/styles/index.scss'

import { NotFound } from '@/pages/not-found'

import { Auth } from '@/widgets/auth'

import { FINAL_QUIZ } from '@/shared/constants'
import { useLocalStorage } from '@/shared/hooks'
import { useQueryParams } from '@/shared/hooks/useQueryParams'

import '@/Assets/sass/default.sass'

export type TStepAuth =
  | 'login'
  | 'registration'
  | 'forgot-password'
  | 'forgot-password-code'
  | 'four-digit-code'
  | 'have-code'
  | ''

export const ProtectedRoute: React.FC<
  PropsWithChildren & { isMain?: boolean; hiddenNoAuth?: boolean; isQuiz?: boolean }
> = ({ children, isMain, hiddenNoAuth, isQuiz }) => {
  const { localValue } = useLocalStorage(FINAL_QUIZ)

  const params = useQueryParams()

  const [pageName, setPageName] = useState<TStepAuth>(
    params?.ref || localValue ? 'registration' : 'login'
  )
  const [dataAuthForm, setDataAuthForm] = useState<{
    [x: string]: string
  }>({})

  const refresh = Cookies.get('refreshToken') || localStorage.getItem('refresh')

  useEffect(() => {
    if (!params?.ref) return
    setPageName('registration')
  }, [params?.ref])

  if (refresh && hiddenNoAuth) return <NotFound />
  if (!refresh && hiddenNoAuth) return children
  if (isMain && !params?.ref) return children
  if (!refresh) {
    if (isQuiz) {
      return <>{children}</>
    } else {
      return <Auth pageName={pageName} setPageName={setPageName} />
    }
  }

  return <>{children}</>
}
