import { Suspense } from 'react'

import { FeedbackFormComponent } from '@/features/feedback-form/ui'

import Layout from '@/shared/ui/layout'
import { LayoutHeader } from '@/shared/ui/layout/header'
import { Loader } from '@/shared/ui/loader'

const FeedbackForm = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Layout
        head_block={<LayoutHeader title={'feedback-form_route'} />}
        goBackLink
        title='feedback-form_route'
        titlePage='feedback-form_route'
        descriptionMeta='feedback-form_route'
        fullWidth={false}
        position='left'>
        <>
          <FeedbackFormComponent />
        </>
      </Layout>
    </Suspense>
  )
}

export default FeedbackForm
