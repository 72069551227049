import { FC } from 'react'

import { Controller } from 'react-hook-form'

import { IPropsImage } from '@/shared/ui/form/form.interface'
import { ImageInputRoundDefault } from '@/shared/ui/form/image-input-round/image-input-round-default'

import { ConnectForm } from './ConnectForm'

export const ConnectInputImageRound: FC<IPropsImage> = ({ registerConfig, img, ...props }) => {
  return (
    <ConnectForm>
      {({ register, control }) => (
        <Controller
          control={control}
          rules={registerConfig}
          name={props.name}
          render={({ field: { onChange, value, ref } }) => {
            return (
              <ImageInputRoundDefault {...props} onChange={onChange} img={img} imageRef={ref} />
            )
          }}
        />
      )}
    </ConnectForm>
  )
}
