import { FC } from 'react'

import clsx from 'clsx'

import { useBasketStore } from '@/app/zustand-store'

import styles from './BetValueSelector.module.scss'

interface IBetValueSelector {
  betValue?: number
  onChange?: (value: number) => void
}

export const BetValueSelector: FC<IBetValueSelector> = ({ betValue, onChange }) => {
  const { increaseStep, decreaseStep } = useBasketStore()

  const onChangeValue = (val: number) => {
    onChange?.(val)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div
          className={clsx(styles.element, styles.increase)}
          onClick={() => {
            if (betValue && betValue > decreaseStep) {
              onChangeValue(betValue - decreaseStep)
            } else {
              onChangeValue(0)
            }
          }}>
          -
        </div>
        <input
          className={styles.element}
          maxLength={16}
          name='stake'
          value={betValue}
          onChange={val => onChangeValue(Number(val.target.value.replace(/\D+/g, '')))}
        />
        <div
          className={clsx(styles.element, styles.increase)}
          onClick={() => onChangeValue((betValue || 0) + increaseStep)}>
          +
        </div>
      </div>
      <div className={styles.row}>
        <div className={clsx(styles.element, styles.digit)} onClick={() => onChangeValue(100)}>
          100
        </div>
        <div className={clsx(styles.element, styles.digit)} onClick={() => onChangeValue(200)}>
          200
        </div>
        <div className={clsx(styles.element, styles.digit)} onClick={() => onChangeValue(500)}>
          500
        </div>
        <div className={clsx(styles.element, styles.digit)} onClick={() => onChangeValue(1000)}>
          MAX
        </div>
      </div>
    </div>
  )
}
