import { useMutation } from '@tanstack/react-query'

import UserService from '@/shared/services/user.service'

import { IUsers } from '@/shared/types/user.types'

export const useSendFeedback = () => {
  const { mutateAsync: sendFeedback } = useMutation(
    ['send feedback'],
    (data: IUsers.IFeedbackForm) => UserService.sendFeedback(data)
  )

  return { sendFeedback }
}
