import { FC } from 'react'

import clsx from 'clsx'

import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { ISport } from '@/shared/types/sport.types'
import { timeFormatter } from '@/shared/utils'

import styles from './SingleBet.module.scss'

interface ISingleBet {
  bet: ISport.IBet
  amount: number
}

export const HistoryBet: FC<ISingleBet> = ({ bet, amount }) => {
  const { getField, getFieldStatic } = useTranslationField()
  const date = timeFormatter(bet.start_date)
  const { isMobile } = useIsMobile()

  const potentialWinning = (Number(bet.markets?.market) || 1) * amount

  return (
    <div className={clsx(styles.wrapper, isMobile && styles.mobileNotSingle)}>
      <div className={styles.content}>
        <div className={styles.col}>
          <div className={styles.row}>
            <div className={styles.label}>{getField(bet, 'tournament_name')}</div>
          </div>
          <div className={styles.label}>{date}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.comands}>
            <div className={styles.comand}>{getField(bet, 'opponent_one_name')}</div>
            <div className={styles.comand}>{getField(bet, 'opponent_two_name')}</div>
          </div>
          <div className={styles.logos}>
            <img src={bet.opponent_one_image} alt='opponent' className={styles.logo} />
            <img src={bet.opponent_two_image} alt='opponent' className={styles.logo} />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.row}>
            <div className={styles.label}>{getFieldStatic('x')}</div>
            <div className={styles.value}>{bet.markets?.market}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>{getFieldStatic('your_bet')}</div>
            <div className={styles.value}>{amount}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>{getFieldStatic('potential_winning')}</div>
            <div className={styles.value}>{potentialWinning.toFixed(2)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
