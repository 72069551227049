import { FC, useState } from 'react'

import { useBasket } from '@/widgets/basket/useBasket'

import { useExpressBet } from '@/features/sports/api/useExpressBet'
import { BasketFooter } from '@/features/sports/bets-basket/tab-content/footer-content'

import SingleBet from '@/entities/sports/bets-basket/single-bet/desktop-single-bet'

import styles from './AccumulatorTabContent.module.scss'

const AccumulatorTabContent: FC = () => {
  const cart = useBasket()
  const marketIds = cart.map(item => item.market.pk)
  const [resetValue, setResetValue] = useState(false)

  const { mutateAsync } = useExpressBet(marketIds)

  let coefficient = 0

  cart.map((el, index) => {
    if (index === 0) coefficient = el.market.market
    else coefficient = coefficient * el.market.market
  })
  const input = document.querySelector<HTMLInputElement>("input[name='stake']")
  const value = input?.value

  const onClick = () => {
    const input = document.querySelector<HTMLInputElement>("input[name='stake']")
    const value = input?.value
    if (!value || value === '0') return null

    mutateAsync(Number(value)).then(() => {
      input!.value = '0'
      setResetValue(true)
      setTimeout(() => {
        setResetValue(false)
      }, 100)
    })
  }

  return (
    <>
      <div className={styles.bets}>
        {cart.map((el, index) => (
          <SingleBet key={index} bet={el} betValue={Number(value)} />
        ))}
      </div>
      <BasketFooter coefficient={coefficient} onSubmitBet={onClick} isSingle={false} />
    </>
  )
}

export default AccumulatorTabContent
