import { Children, FC, ReactNode } from 'react'

interface ITabsBody {
  activeTab: number
  children: ReactNode | ReactNode[]
}

export const TabsBody: FC<ITabsBody> = ({ activeTab, children }) => {
  const result = Children.toArray(children)

  return result[activeTab]
}
