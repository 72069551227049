import { GameList } from '../games-list'

import styles from './GamesCatalog.module.scss'

export const GamesCatalog = () => {
  return (
    <div className={styles.wrapper}>
      {/* <GamesSliderCategory />
			<FilterBlock /> */}
      <GameList />
    </div>
  )
}
