import { FC } from 'react'

import { useGetScreenResolution } from '@/shared/hooks/useGetScreenResolution'

import DesktopBetsBasket from './bets-basket'

const BetsBasket: FC = () => {
  const { width } = useGetScreenResolution()

  const isDesktop = width > 1280

  return isDesktop ? <DesktopBetsBasket /> : null
}

export default BetsBasket
