import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { tooltips } from '@/app/store/constants'
import { IntroTooltip } from '@/app/store/types'

interface IntroTooltipState {
  active: boolean
  changeActive: () => void
  activeTooltip: IntroTooltip
  setNextActiveTooltip: () => void
}

export const useIntroTooltipStore = create<IntroTooltipState>()(
  persist(
    (set, get) => ({
      active: true,
      changeActive: () => set(state => ({ active: false })),
      activeTooltip: tooltips[0],
      setNextActiveTooltip: () =>
        set(state => ({ activeTooltip: tooltips.find(el => el.id === state.activeTooltip.id + 1) }))
    }),
    {
      name: 'tooltip-storage',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
