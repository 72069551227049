import { FC } from 'react'

import clsx from 'clsx'

import { useBasketStore } from '@/app/zustand-store'

import { useBasket } from '@/widgets/basket/useBasket'

import { CloseBasketJSX, SettingsBasketJSX } from '@/shared/assets/common/svg'
import { useTranslationField } from '@/shared/hooks'

import styles from './CouponHeader.module.scss'
import { ICouponHeader } from './coupon-header.interface'

const CouponHeader: FC<ICouponHeader> = ({ closeBasket, screen, changeScreen }) => {
  const { getFieldStatic } = useTranslationField()
  const { isSettings, changeIsSettings } = useBasketStore()

  const cart = useBasket()

  const openSettings = () => {
    changeIsSettings(true)
  }

  const onClickClose = () => {
    isSettings ? changeIsSettings(false) : closeBasket()
  }

  return (
    <div className={styles.basketHeader}>
      {isSettings ? (
        <div className={styles.content}>{getFieldStatic('basket_settings')}</div>
      ) : (
        <div className={styles.content}>
          <SettingsBasketJSX className={styles.settings} onClick={openSettings} />
          <div
            className={clsx(styles.basketButton, screen === 'basket' && styles.active)}
            onClick={() => changeScreen('basket')}>
            <div>{getFieldStatic('basket')}</div>
            <div className={styles.num}>
              <span>{cart.length}</span>
            </div>
          </div>
          <div
            className={clsx(styles.basketButton, screen === 'history' && styles.active)}
            onClick={() => changeScreen('history')}>
            {getFieldStatic('history')}
          </div>
        </div>
      )}
      <CloseBasketJSX onClick={onClickClose} className={styles.close} />
    </div>
  )
}

export default CouponHeader
