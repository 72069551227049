import { FC, useEffect } from 'react'

import { FieldValues, useForm } from 'react-hook-form'

import { useSendFeedback } from '@/features/feedback-form/api'
import { useProfileData } from '@/features/profile/api'

import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { IUsers } from '@/shared/types/user.types'
import { Button, FormWrapper, Input, TextArea } from '@/shared/ui/form'
import { addMessageToast } from '@/shared/utils'

import styles from './FeedbackForm.module.scss'

export const FeedbackFormComponent: FC = () => {
  const { getFieldStatic } = useTranslationField()
  const { userData } = useProfileData(undefined)
  const { sendFeedback } = useSendFeedback()
  const { isMobile } = useIsMobile()

  const methods = useForm({
    mode: 'all'
  })

  const {
    formState: { errors },
    setValue
  } = methods

  useEffect(() => {
    setValue('name', userData?.name)

    setValue('email', userData?.email)
  }, [setValue, userData])

  const onSubmit = async (data: FieldValues) => {
    try {
      const request = {
        ...data,
        name: `${data.name} | ${userData?.id}`
      }

      await sendFeedback(request as IUsers.IFeedbackForm)
      addMessageToast(getFieldStatic('feedback-form-message-send'), 'success')
      methods.reset({
        email: '',
        name: '',
        message: ''
      })
    } catch (error) {
      addMessageToast(error, 'error')
    }
  }

  return (
    <>
      <div className={styles.infoWrapper}>
        <div className={styles.info}>{getFieldStatic('feedback-form-info-start')}</div>
        <div
          className={styles.info}
          dangerouslySetInnerHTML={{ __html: getFieldStatic('feedback-form-info-end') }}
        />
      </div>
      <FormWrapper onSubmit={v => onSubmit(v)} methods={methods} className={styles.wrapper}>
        {isMobile ? (
          <>
            <Input
              name='email'
              connect
              containerClassName={styles.inputContainer}
              className={styles.input}
              errorText={errors.email?.message?.toString()}
              placeholder={getFieldStatic('enter_email')}
              isClear
              methods={methods}
              registerConfig={{
                required: getFieldStatic('required_field')
              }}
            />
            <Input
              name='name'
              connect
              containerClassName={styles.inputContainer}
              className={styles.input}
              errorText={errors.name?.message?.toString()}
              placeholder={getFieldStatic('enter_name')}
              methods={methods}
              registerConfig={{
                required: getFieldStatic('required_field')
              }}
            />
            <TextArea
              name={'message'}
              className={styles.input}
              containerClassName={styles.inputContainer}
              classNameErrorWrapper={styles.inputError}
              classNameError={styles.error}
              placeholder={getFieldStatic('enter_text')}
              connect
              minRows={16}
              errorText={errors.message?.message?.toString()}
              registerConfig={{
                required: getFieldStatic('required_field')
              }}
            />
            <Button classNameContainer={styles.button} type='submit' modifiers={['red']}>
              {getFieldStatic('send')}
            </Button>
          </>
        ) : (
          <>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.label}>{getFieldStatic('enter_email')}</div>
                <Input
                  name='email'
                  connect
                  className={styles.input}
                  errorText={errors.email?.message?.toString()}
                  placeholder={getFieldStatic('email')}
                  isClear
                  methods={methods}
                  registerConfig={{
                    required: getFieldStatic('required_field')
                  }}
                />
              </div>
              <div className={styles.col}>
                <div className={styles.label}>{getFieldStatic('enter_name')}</div>
                <Input
                  name='name'
                  connect
                  className={styles.input}
                  errorText={errors.name?.message?.toString()}
                  placeholder={getFieldStatic('name')}
                  isClear
                  methods={methods}
                  registerConfig={{
                    required: getFieldStatic('required_field')
                  }}
                />
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.label}>{getFieldStatic('enter_text')}</div>
              <TextArea
                name={'message'}
                className={styles.input}
                classNameErrorWrapper={styles.inputError}
                classNameError={styles.error}
                placeholder={getFieldStatic('message')}
                connect
                minRows={16}
                errorText={errors.message?.message?.toString()}
                registerConfig={{
                  required: getFieldStatic('required_field'),
                  maxLength: {
                    value: 1000,
                    message: getFieldStatic('maximum_characters') + ' - 1000'
                  }
                }}
              />
            </div>
            <Button classNameContainer={styles.button} type='submit' modifiers={['red']}>
              {getFieldStatic('send')}
            </Button>
          </>
        )}
      </FormWrapper>
    </>
  )
}
