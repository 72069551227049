import { FC, PropsWithChildren, Suspense } from 'react'

import { useForwardPostBack } from '@/app/hooks'
import ErrorBoundary from '@/app/providers/error-boundary'

import BetsBasket from '@/widgets/basket/BetsBasket'

import { Loader } from '../loader'

export const SuspenseWrapper: FC<PropsWithChildren> = ({ children }) => {
  useForwardPostBack()

  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>{children}</ErrorBoundary>
      <BetsBasket />
    </Suspense>
  )
}
