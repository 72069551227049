import { FC } from 'react'

import { Controller } from 'react-hook-form'

import { IPropsImage } from '@/shared/ui/form/form.interface'
import { ImageInputDefault } from '@/shared/ui/form/image-input/image-input-default'

import { ConnectForm } from './ConnectForm'

export const ConnectInputImage: FC<IPropsImage> = ({ registerConfig, img, ...props }) => {
  return (
    <ConnectForm>
      {({ register, control }) => (
        <Controller
          control={control}
          rules={registerConfig}
          name={props.name}
          render={({ field: { onChange, value, ref } }) => {
            return <ImageInputDefault {...props} onChange={onChange} img={img} imageRef={ref} />
          }}
        />
      )}
    </ConnectForm>
  )
}
