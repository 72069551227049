import { useTranslationField } from '@/shared/hooks/index'
import { addMessageToast } from '@/shared/utils/addMessageToast'

export const useImageToBase64 = () => {
  const { getFieldStatic } = useTranslationField()

  const imageToBase64 = async (imageFile: File): Promise<string | undefined> => {
    if (imageFile instanceof File) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = reject
        reader.readAsDataURL(imageFile)
      })
    } else {
      addMessageToast(getFieldStatic('compress_image_error'), 'error')
    }
  }

  return {
    imageToBase64
  }
}
