import React, { FC, useEffect, useState } from 'react'

import cn from 'clsx'

import { useBasketStore } from '@/app/zustand-store'

import { useBasket } from '@/widgets/basket/useBasket'

import AccumulatorTabContent from '@/features/sports/bets-basket/tab-content/accumulator-tab-content'
import { ActiveHistory } from '@/features/sports/bets-basket/tab-content/history-content/active-history'
import { CalculatedHistory } from '@/features/sports/bets-basket/tab-content/history-content/calculated-history'
import { SettingsContent } from '@/features/sports/bets-basket/tab-content/settings-content'
import SinglesTabContent from '@/features/sports/bets-basket/tab-content/singles-tab-content'

import CouponHeader from '@/entities/sports/bets-basket/coupon-header/desktop-coupon-header'
import { BasketScreen } from '@/entities/sports/bets-basket/coupon-header/desktop-coupon-header/coupon-header.interface'

import { TrashBasketJSX } from '@/shared/assets/common/svg'
import { BasketJSX } from '@/shared/assets/header/svg'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import CouponTabs from '@/shared/sports/coupon-tabs/desktop-coupon-tabs/CouponTabs'
import { TabsBody } from '@/shared/sports/coupon-tabs/tab-body'

import styles from './DesktopBetsBasket.module.scss'

const DesktopBetsBasket: FC = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { changeIsOpen, isOpen, isSettings } = useBasketStore()
  const { getFieldStatic } = useTranslationField()
  const [screen, setScreen] = useState<BasketScreen>('basket')
  const { isMobile } = useIsMobile(1200)
  const cart = useBasket()
  const closeBasket = () => {
    changeIsOpen(false)
  }

  const changeScreen = (screen: BasketScreen) => {
    setScreen(screen)
  }

  useEffect(() => {
    if (!isOpen) {
      window.document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  const isBlockedBodyScroll = (isLeave?: boolean) => {
    if (!isOpen) return
    window.document.body.style.overflow = isLeave ? 'auto' : 'hidden'
  }

  const delete_all = () => {
    localStorage.setItem('cart', '[]')
    window.dispatchEvent(new Event('storage'))
  }

  return (
    <>
      {isMobile && (
        <div className={styles.couponButtonWidget} onClick={() => changeIsOpen(true)}>
          <BasketJSX />
          {!!cart.length && <span className={styles.count}>{cart.length}</span>}
        </div>
      )}
      <div
        onMouseLeave={() => {
          isBlockedBodyScroll(true)
        }}
        onMouseEnter={() => isBlockedBodyScroll()}
        className={cn(styles.coupon, {
          [styles.opened]: isOpen
        })}>
        <CouponHeader closeBasket={closeBasket} screen={screen} changeScreen={changeScreen} />
        {isSettings ? (
          <SettingsContent />
        ) : (
          <div className={styles.couponBody}>
            <CouponTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              isHistory={screen === 'history'}
            />

            {screen === 'basket' ? (
              <>
                <div className={styles.deleteAll}>
                  <div className={styles.text} onClick={delete_all}>
                    {getFieldStatic('delete_all')}
                  </div>
                  <TrashBasketJSX onClick={delete_all} />
                </div>
                <TabsBody activeTab={activeTab}>
                  <AccumulatorTabContent />
                  <SinglesTabContent />
                </TabsBody>
              </>
            ) : (
              <>
                <TabsBody activeTab={activeTab}>
                  <ActiveHistory />
                  <CalculatedHistory />
                </TabsBody>
              </>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default DesktopBetsBasket
