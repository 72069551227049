// Библиотечные компоненты
import { FC } from 'react'

import clsx from 'clsx'

import { useAuthStore, useBasketStore } from '@/app/zustand-store'

import { useBasket } from '@/widgets/basket/useBasket'

import { LangChanger } from '@/features/header/ui'

import { NavMenuItem } from '@/entities/header'

import { BasketJSX } from '@/shared/assets/header/svg'
import { useTranslationField } from '@/shared/hooks'
import { LinkItemMenuProps } from '@/shared/types/global.types'
import { IUsers } from '@/shared/types/user.types'

import styles from './NavMenu.module.scss'

interface IProps {
  dataTabMenu: Array<LinkItemMenuProps>
  userData?: IUsers.UserRating
  withCover?: boolean
  activeTooltips?: boolean
}

export const NavMenu: FC<IProps> = ({ dataTabMenu, userData, withCover, activeTooltips }) => {
  const { getFieldStatic } = useTranslationField()
  const { changeIsOpen, isOpen } = useBasketStore()
  const { authorized } = useAuthStore()
  const cart = useBasket()

  const toggleOpenBasket = () => {
    changeIsOpen(!isOpen)
  }

  return (
    <>
      <div className={styles.wrapper}>
        {dataTabMenu.map((item_menu, index) => {
          if (item_menu.link === '/lang')
            return (
              <>
                {authorized && (
                  <div
                    className={clsx(styles.basket, isOpen && styles.basketOpen)}
                    onClick={toggleOpenBasket}
                    key={'basket'}>
                    {getFieldStatic('basket')}
                    <BasketJSX />
                    {cart.length > 0 && (
                      <div className={clsx(styles.cart, isOpen && styles.cartOpen)}>
                        {cart.length}
                      </div>
                    )}
                  </div>
                )}
                <div style={{ marginRight: '14px' }} key={'lang-changer'}>
                  <LangChanger />
                </div>
              </>
            )

          return (
            <NavMenuItem
              key={item_menu.link}
              data={item_menu}
              // index={index}
              userData={userData}
              withCover={withCover}
            />
          )
        })}
      </div>
    </>
  )
}
