import React, { FC, PropsWithChildren, ReactNode } from 'react'

import clsx from 'clsx'

import { useTranslationField } from '@/shared/hooks'
import { Button } from '@/shared/ui/form'

import styles from './quiz-content.module.scss'

interface IProps extends PropsWithChildren {
  title?: string
  title2?: string
  classNameWrapper?: string
  classNameButton?: string
  maxWidth?: string
  buttonsJSX?: ReactNode
  onFinal?: () => void
  onNextStep?: () => void
}

export const QuizContent: FC<IProps> = ({
  title,
  classNameWrapper,
  children,
  maxWidth,
  onFinal,
  onNextStep,
  classNameButton,
  buttonsJSX,
  title2
}) => {
  const { getFieldStatic } = useTranslationField()
  return (
    <div className={clsx(styles.wrapper, classNameWrapper)} style={{ maxWidth }}>
      <div className={clsx(styles.content, 'animate__animated animate__backInRight')}>
        {title && <h2 className='title'>{title}</h2>}
        {title2 && <h2 className='title'>{title2}</h2>}
        {children}
      </div>
      {onFinal ? (
        <div className={clsx(styles.buttonCollect, 'animate__animated animate__bounce')}>
          <Button
            classNameContainer={classNameButton}
            modifiers={['red']}
            onClick={() => onFinal?.()}>
            {getFieldStatic('collect')}
          </Button>
        </div>
      ) : buttonsJSX ? (
        <>
          <div className={clsx(styles.buttons)}>{buttonsJSX}</div>
        </>
      ) : (
        <div className={clsx(styles.buttons)}>
          <Button modifiers={['gold']} onClick={() => onNextStep?.()}>
            {getFieldStatic('no')}
          </Button>
          <Button modifiers={['red']} onClick={() => onNextStep?.()}>
            {getFieldStatic('yes')}
          </Button>
        </div>
      )}
    </div>
  )
}
