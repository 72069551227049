import React, { FC, PropsWithChildren, Suspense, useEffect, useRef, useState } from 'react'

import clsx from 'clsx'
import { useInView } from 'react-intersection-observer'
import { useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

import { dataTab } from '@/app/store/constants'
import { useBasketStore, useIntroTooltipStore } from '@/app/zustand-store'

import { typePage } from '@/pages/main/types'

import { Footer } from '@/widgets/footer'

import { FeedbackFormJSX, FeedbackFormLargeJSX, ScrollArrowJSX } from '@/shared/assets/common/svg'
import {
  useIsMobile,
  useLockedScroll,
  useQueryParams,
  useScrollHook,
  useTranslationField
} from '@/shared/hooks'
import { usePageViews } from '@/shared/hooks/usePageViews'
import { LinkItemMenuProps } from '@/shared/types/global.types'
import { TValuesLocality } from '@/shared/utils/getMessageLocality'

import Breadcrumbs from '../breadcrumbs'
import ScrollButton from '../buttons/scroll-top'
import { Loader } from '../loader'

import styles from './Layout.module.scss'

const yCoordinatesStep = 1050

const Header = React.lazy(() => import('@/widgets/header'))

type TLayout = typePage & PropsWithChildren

//TODO сильно переписать хедер
const Layout: FC<TLayout> = ({
  children,
  withHeaderCover,
  titlePage,
  descriptionMeta,
  position,
  head_block,
  ad_block,
  reverseAd,
  fullWidth,
  style,
  fullComponent,
  hiddenBreadcrumbs,
  hiddenBreadcrumbsAll,
  extraMenuBlock,
  modifers,
  isCenterBreadcrumbs,
  footerHidden,
  replaceRouteName,
  fullHeight,
  hiddenQueryTab,
  goBackLink,
  titlePosition = 'center',
  menuName,
  customBreadcrumbs
}) => {
  const mod = {
    article: false,
    container: false,
    fullHeight: false,
    slots: false,
    fullWidth: false
  }

  modifers?.forEach(item => {
    mod[item] = true
  })

  const ref = useRef<HTMLDivElement>(null)
  const { getField, getFieldStatic, locale } = useTranslationField()
  const [height, setHeight] = useState<number | null>(null)
  const { isMobile: isTablet } = useIsMobile(1180)
  const { isOpen } = useBasketStore()
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const location = useLocation()
  const pathnameLength = location.pathname.split('/').length
  const search = useQueryParams()
  const intl = useIntl()
  const [dataTabMenu, setDataTabMenu] = useState<Array<LinkItemMenuProps>>(dataTab)
  usePageViews(dataTabMenu, setDataTabMenu)
  const { ref: refView, inView } = useInView({
    threshold: 0
  })
  const { active } = useIntroTooltipStore()
  const [currentY, setCurrentY] = useState(850)
  const [activeScroll, setActiveScroll] = useState(true)
  const { scrollPositionTop } = useScrollHook()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    if (ad_block) {
      const container = ref.current!
      const observer = new MutationObserver(() => {
        setHeight(container.clientHeight)
      })

      if (container) {
        observer.observe(container, { childList: true, subtree: true })
      }

      return () => {
        if (container) {
          observer.disconnect()
        }
      }
    }
  }, [])

  useLockedScroll(active)

  const mainBlock = document.getElementById('main-block')
  const left = mainBlock?.getBoundingClientRect().left
  const rightMainBlock = (mainBlock?.getBoundingClientRect()?.right || 1440) - 1440

  const isVisibleForm =
    !isOpen && mainBlock && pathnameLength <= 2 && !location.pathname.includes('feedback-form')
  const isVisibleArrow = !isOpen && mainBlock && location.pathname === '/' && !isTablet

  const tabName = search?.tab ? search?.tab + '_route' : null

  useEffect(() => {
    if (titlePage) document.title = intl.formatMessage({ id: titlePage })
    if (descriptionMeta) {
      const dataText = intl.formatMessage({ id: descriptionMeta })
      document.querySelector('meta[name="description"]')!.setAttribute('content', dataText)
    }
  }, [locale, location, titlePage, descriptionMeta])

  const scrollTo = () => {
    if (activeScroll) {
      window.scrollTo(0, currentY)
      if (scrollPositionTop > currentY + yCoordinatesStep / 2) {
        setCurrentY(currentY + yCoordinatesStep)
      }
      if (scrollPositionTop > currentY - yCoordinatesStep / 2) {
        setCurrentY(currentY - yCoordinatesStep)
      }
      setCurrentY(currentY + yCoordinatesStep)
    } else {
      window.scrollTo(0, 0)
      setActiveScroll(true)
      setCurrentY(850)
    }
  }

  useEffect(() => {
    if (scrollPositionTop > 8400) {
      setActiveScroll(false)
    }
    if (scrollPositionTop === 0) {
      setActiveScroll(true)
    }
  }, [scrollPositionTop])

  return (
    <div
      id='App'
      className={clsx('App', styles.containerApp)}
      onMouseDown={event => {
        // закрытие под меню при за его пределами
        const openSubMenu = document.querySelector('.subMenu.active')
        if (openSubMenu && !openSubMenu.contains(event.target as Node)) {
          openSubMenu.classList.remove('active')
        }
      }}>
      <Suspense fallback={<Loader />}>
        <Header
          dataTabMenu={dataTabMenu}
          setDataTabMenu={setDataTabMenu}
          withCover={withHeaderCover}
          activeTooltips={active}
        />
      </Suspense>
      {active && <div className={styles.tooltipAppWrapper}></div>}
      {/* //TODO: Отключить чат и аналитику */}
      {/* <Chat /> */}
      <main className={styles.main}>
        {isTablet ? (
          <div className={styles.startLineInfoWrapper}>
            {location?.pathname !== '/' && (
              <>
                <div
                  className={clsx(
                    styles.startLineInfo,
                    extraMenuBlock && styles.row,
                    extraMenuBlock?.actionBlock && styles.left,
                    (extraMenuBlock?.component || isCenterBreadcrumbs) && styles.spaceBetween,
                    titlePosition === 'center' && styles.positionCenter
                  )}>
                  <Breadcrumbs
                    goBack={goBackLink}
                    hiddenBreadcrumbsAll={hiddenBreadcrumbsAll}
                    replaceRouteName={replaceRouteName}
                    customBreadcrumbs={customBreadcrumbs}
                    customNameMobi={getFieldStatic(tabName as TValuesLocality) || menuName}
                  />

                  {/* //TODO скорее всего выпилиться  */}
                  {/* {extraMenuBlock && (
										<>
											<BreadcrumbsExtra
												isTab={!!tabName}
												isOpenMenu={isOpenMenu}
												setIsOpenMenu={setIsOpenMenu}
												navBreadCrumbs={navBreadCrumbs}
											/>
										</>
									)} */}

                  {isCenterBreadcrumbs && <div style={{ width: '22px' }} />}
                  {/* //TODO скорее всего выпилиться  */}
                  {/* {!isOpenMenu && extraMenuBlock?.component && (
										<button
											className={styles.extraMenuButton}
											onClick={() => setIsOpenMenu(true)}
										>
											<img src={extraMenuBlock?.icon} alt={'button-action'} />
										</button>
									)}

									{extraMenuBlock?.actionBlock && extraMenuBlock.actionBlock}

									{isOpenMenu && (
										<>
											<button className={styles.extraMenuButton} />
											<div className={styles.content}>
												{extraMenuBlock?.component}
											</div>
										</>
									)} */}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className='AppMainBlock'>
            {!hiddenBreadcrumbs && (
              <Breadcrumbs
                idName={titlePage}
                hiddenQueryTab={hiddenQueryTab}
                replaceRouteName={replaceRouteName}
                customBreadcrumbs={customBreadcrumbs}
              />
            )}
          </div>
        )}
        {!!head_block && (
          <div className='AppMainBlock' style={isTablet ? { marginTop: 0 } : {}}>
            {head_block}
          </div>
        )}
        {fullComponent && <div className={styles.fullBlock}>{fullComponent}</div>}
        {ad_block && reverseAd && <div className={styles.adBlock}>{ad_block}</div>}
        <div
          className={clsx(
            `AppMainBlock`,
            mod.article && styles.articleWrapper,
            mod.container && styles.layout,
            mod.fullWidth && styles.fullWidth,
            mod.slots && styles.slotsWrapper,
            fullHeight && styles.fullHeight,
            fullWidth && 'fullDisplay'
          )}
          id={'main-block'}
          style={style}>
          {isVisibleForm && !active && (
            <Link
              to={'/feedback-form'}
              className={styles.feedbackForm}
              style={
                isTablet
                  ? {}
                  : {
                      right: `${left && rightMainBlock > 40 && left - 100 > 0 ? left - 100 : 10}px`,
                      position: inView ? 'absolute' : 'fixed',
                      top: `${inView ? mainBlock?.scrollHeight + 'px' : 'auto'}`,
                      bottom: `${inView ? 'auto' : 70 + 'px'}`,
                      zIndex: 100
                    }
              }>
              {isTablet ? <FeedbackFormJSX /> : <FeedbackFormLargeJSX />}
            </Link>
          )}
          {isVisibleArrow && (
            <ScrollArrowJSX
              className={clsx(styles.arrow, !activeScroll && styles.rotated)}
              style={{
                right: `${left && rightMainBlock > 40 && left - 100 > 0 ? left - 100 : 10}px`,
                position: inView ? 'absolute' : 'fixed',
                top: `${inView ? mainBlock?.scrollHeight - 92 + 'px' : 'auto'}`,
                bottom: `${inView ? 'auto' : 162 + 'px'}`,
                zIndex: 100
              }}
              onClick={scrollTo}
            />
          )}
          <div
            className={clsx(
              'App-mainBlock',
              position,
              !ad_block && `fullWidth`,
              ad_block && styles.noneFull
            )}>
            <div className='content' ref={ref} style={{ display: 'flex', flexDirection: 'column' }}>
              {children}
            </div>
          </div>
          {ad_block && !reverseAd && (
            <div
              style={{
                maxHeight: `${height}px`
              }}
              className={styles.adBlock}>
              {ad_block}
            </div>
          )}
        </div>
        <div ref={refView} />
        <div>
          <ScrollButton />
        </div>
      </main>
      {!footerHidden && <Footer />}
    </div>
  )
}

export default Layout
