import React, { Dispatch, FC, useEffect, useState } from 'react'

import clsx from 'clsx'
import { FaPlus } from 'react-icons/fa6'
import { Link, useNavigate } from 'react-router-dom'

import {
  useAuthStore,
  useBasketStore,
  useIntroTooltipStore,
  useProfileStore
} from '@/app/zustand-store'

import { HeaderTooltip } from '@/widgets/header-tooltip'

import { LangChanger } from '@/features/header/ui'
import { useProfileData } from '@/features/profile/api/useProfileData'

import { SocialBlock } from '@/entities/header/social-block'

import { CloseMenuJSx, MenuJSX } from '@/shared/assets/header/svg'
import { avatarIcon } from '@/shared/assets/profile/img'
import { ProfileStarJSX } from '@/shared/assets/profile/svg'
import { useTranslationField } from '@/shared/hooks'
import { LinkItemMenuProps } from '@/shared/types/global.types'
import { Accordion } from '@/shared/ui/accordion'
import { Button } from '@/shared/ui/form'
import { reFormatNum } from '@/shared/utils'

import { Logo } from '../header-logo'

import styles from './MobileHeader.module.scss'

interface IProps {
  showMenu: boolean | undefined
  setShowMenu: Dispatch<React.SetStateAction<boolean | undefined>>
  dataTabMenu: LinkItemMenuProps[]
  withCover?: boolean
  withToolTips?: boolean
}

export const MobileHeaderTop: FC<IProps> = ({
  showMenu,
  setShowMenu,
  dataTabMenu,
  withToolTips,
  withCover
}) => {
  const [active, setActive] = useState<boolean | undefined>(withToolTips)
  const { userData } = useProfileData()
  const { getFieldStatic } = useTranslationField()
  const navigate = useNavigate()
  const { changeIsCovered } = useProfileStore()
  const [avatar, setAvatar] = useState(avatarIcon)
  const { authorized } = useAuthStore()
  const { activeTooltip, active: activeTooltips } = useIntroTooltipStore()
  const { isOpen, changeIsOpen } = useBasketStore()

  const closeMenu = () => {
    setShowMenu(false)
  }

  const setIsProfileCovered = () => {
    if (withCover) {
      changeIsCovered(true)
    }
  }

  useEffect(() => {
    setShowMenu(activeTooltips)
  }, [activeTooltips])

  useEffect(() => {
    if (userData?.user_properties?.photo && authorized) {
      setAvatar(userData?.user_properties?.photo)
    } else {
      setAvatar(avatarIcon)
    }
  }, [userData, authorized])

  const toggleOpenMenu = () => {
    if (isOpen) {
      changeIsOpen(false)
    }
    setShowMenu(prev => !prev)
  }

  const closeBasket = () => {
    isOpen && changeIsOpen(false)
  }

  return (
    <>
      <div
        className={clsx(styles.wrapperMobile, withCover && styles.withCoverWrapper)}
        onClick={closeBasket}>
        {withCover && <div className={styles.cover} />}
        <div className={styles.logoMenu}>
          <div className={clsx(styles.menu, styles.active)} onClick={toggleOpenMenu}>
            {showMenu ? <CloseMenuJSx /> : <MenuJSX />}
          </div>
          {!showMenu && <Logo />}
        </div>
        {showMenu ? (
          <LangChanger isMobile />
        ) : (
          <div className={styles.avatarMobileWrapper}>
            {userData ? (
              <>
                <Link to={'/profile?tab=deposit-history'}>
                  <div className={styles.wallet}>
                    <div className={styles.plus}>
                      <FaPlus />
                    </div>
                    <div className={styles.count}>₿ {reFormatNum(userData?.wallet)}</div>
                  </div>
                </Link>
                <Link
                  to={'/profile'}
                  className={clsx(
                    styles.profile,
                    withCover && styles.withCover,
                    userData?.bonus_is_ready && styles.ready
                  )}
                  onClick={setIsProfileCovered}>
                  <div className={styles.editAvatar}>
                    <img src={avatar} alt='face' />
                    {userData.bonus_is_ready && <ProfileStarJSX />}
                  </div>
                </Link>
                {/* //TODO Без фото
								<Link to={'/profile'} className={styles.profile}>
									<ProfileIconJSX />
								</Link> */}
              </>
            ) : (
              <>
                <Button
                  onClick={() => navigate('/profile')}
                  modifiers={['red', 'ultraSmall', 'textCapitalize']}>
                  {getFieldStatic('login')}
                </Button>
              </>
            )}
          </div>
        )}
      </div>
      {showMenu && (
        <div className={clsx(styles.menuOpen, activeTooltips && styles.menuOpenTooltips)}>
          {activeTooltips && <div className={styles.cover} />}
          {!userData && (
            <>
              {!withToolTips && (
                <div className={styles.loginProfile}>
                  <div className={styles.title}>{getFieldStatic('login_account')}</div>
                  <div className={styles.text}>{getFieldStatic('login_text')}</div>
                  <Button
                    onClick={() => navigate('/profile')}
                    modifiers={['red', 'ultraSmall', 'textCapitalize']}>
                    {getFieldStatic('login')}
                  </Button>
                </div>
              )}
            </>
          )}
          {dataTabMenu.map(item => {
            if (['/profile', '/lang'].includes(item.link)) return null
            return (
              <div className={styles.menuListElement} key={item.itemMenu.itemName}>
                <Accordion
                  name={getFieldStatic(item.itemMenu.itemName as any)}
                  closeMenu={closeMenu}
                  link={!item.itemMenu.subMenu?.length ? item.link : undefined}
                  isActive={item.itemMenu.active}>
                  <div className={styles.menuList}>
                    {item.itemMenu.subMenu?.map(item => (
                      <Link to={item.link} key={item.itemMenu.itemName}>
                        {getFieldStatic(item.itemMenu.itemName as any)}
                      </Link>
                    ))}
                  </div>
                </Accordion>
                {activeTooltip.name === item.itemMenu.itemName && activeTooltips && (
                  <HeaderTooltip />
                )}
              </div>
            )
          })}
          <div className={styles.social}>
            <SocialBlock isMobile />
          </div>
        </div>
      )}
    </>
  )
}
