import { create } from 'zustand'

interface ProfileState {
  isCovered: boolean
  changeIsCovered: (authorized: boolean) => void
}

export const useProfileStore = create<ProfileState>(set => ({
  isCovered: false,
  changeIsCovered: (isCovered: boolean) => set(state => ({ isCovered: isCovered }))
}))
