import { FC } from 'react'

import clsx from 'clsx'

import { reFormatNum } from '@/shared/utils'

import styles from './BettingCoefficient.module.sass'

interface IProps {
  status:
    | 'won'
    | 'loss'
    | 'none'
    | 'progress'
    | 'refund'
    | 'refund_error'
    | 'win_half_1'
    | 'win_half_2'
    | 'lose_half_1'
    | 'lose_half_2'

  coeff: number
  className?: string
  isMobi?: boolean
}
export const BettingCoefficient: FC<IProps> = ({ coeff, status, className, isMobi }) => {
  return (
    <div className={clsx(styles.coefficient, className, isMobi && styles.isMobi, styles[status])}>
      <span>{reFormatNum(coeff)}</span>
    </div>
  )
}
