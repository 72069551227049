import imageCompression from 'browser-image-compression'

import { useTranslationField } from '@/shared/hooks/index'
import { addMessageToast } from '@/shared/utils/addMessageToast'

export const useConvertImageToWebP = () => {
  const { getFieldStatic } = useTranslationField()

  const convert = async (file: File, maxSizeMB: number = 3): Promise<File> => {
    const options = {
      maxSizeMB: maxSizeMB,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: 'image/webp'
    }
    try {
      const compressedFile = await imageCompression(file, options)
      return new File([compressedFile], file.name.replace(/\.[^/.]+$/, '.webp'), {
        type: 'image/webp'
      })
    } catch (error) {
      addMessageToast(`${getFieldStatic('compress_image_error')}: ${error}`, 'error')
      throw error
    }
  }

  return {
    convert
  }
}
