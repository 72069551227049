import { FC, useState } from 'react'

import clsx from 'clsx'
import { IoIosArrowForward, IoIosArrowUp } from 'react-icons/io'

import { useTranslationField } from '@/shared/hooks'
import { IUsers } from '@/shared/types/user.types'
import { Button } from '@/shared/ui/form'
import { dateFormatter } from '@/shared/utils'

import styles from './PaymentCard.module.scss'
import { MoreInfo } from './slices'

interface IProps {
  data: IUsers.IPaymentHistoryItem
}
export const PaymentCard: FC<IProps> = ({ data }) => {
  const { getFieldStatic, getField, locale } = useTranslationField()
  const [isOpen, setIsOpen] = useState(false)

  const onOpenDetails = () => {
    setIsOpen(prev => !prev)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.body} onClick={onOpenDetails}>
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.columnName}>{getFieldStatic('product')}</div>
            <div className={styles.value}>{getFieldStatic(data.type)}</div>
          </div>
          <div className={clsx(styles.row, styles.right)}>
            <div className={styles.text}>{getField(data.item, 'name')}</div>
          </div>
        </div>

        <span className={clsx(styles.arrow, isOpen && styles.rotate)}>
          <IoIosArrowUp />
        </span>
      </div>

      <MoreInfo data={data} isOpen={isOpen} />

      {!isOpen && (
        <Button
          className={styles.button}
          modifiers={['red', 'small', 'textCapitalize']}
          onClick={onOpenDetails}>
          {getFieldStatic('view_details')}
        </Button>
      )}
    </div>
  )
}
