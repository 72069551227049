import { toFormData } from 'axios'

import { axiosClassic, axiosInstance } from '@/shared/api'
import { getUserUrl } from '@/shared/api/config/api.config'
import { IUsers } from '@/shared/types/user.types'

import { removeTokensStorage, saveTokensStorage } from './auth.helper'

const AuthService = {
  async register(data: IUsers.IRegister) {
    const ref = data.ref

    delete data.ref

    const response = await axiosClassic.post<string>(getUserUrl('/register'), toFormData(data), {
      params: { ref }
    })

    return response
  },

  async registerDemo() {
    const response = await axiosClassic.post<IUsers.ResponseDemoRegister>(
      getUserUrl('/demo_user_register')
    )
    if (response.data.access) saveTokensStorage(response.data)

    return response
  },

  async activation(data: IUsers.IEmailCode) {
    const response = await axiosClassic.post<IUsers.IAuthResponse>(
      getUserUrl('/activate'),
      toFormData(data)
    )

    if (response.data.access) saveTokensStorage(response.data)

    return response
  },

  async getTokens(data: IUsers.IEmailPassword) {
    const response = await axiosClassic.post<IUsers.IAuthResponse>(
      getUserUrl('/access'),
      toFormData(data)
    )

    if (response.data.access) saveTokensStorage(response.data)
    AuthService.getMe()

    return response
  },

  async getNewTokens() {
    const refreshToken = localStorage.getItem('refresh')
    try {
      const response = await axiosClassic.get<IUsers.ITokens>(getUserUrl('/refresh'), {
        headers: { Authorization: `Bearer ${refreshToken}` }
      })

      if (response.data.access) saveTokensStorage(response.data)

      return response
    } catch (error: any) {
      if ([401, 4003].includes(error.response.status)) this.logout()
    }

    const response = await axiosClassic.get<IUsers.ITokens>(getUserUrl('/refresh'), {
      headers: { Authorization: `Bearer ${refreshToken}` }
    })
  },

  async logout() {
    document.body.removeAttribute('aria-id')
    removeTokensStorage()
  },

  async getMe() {
    const response = await axiosInstance.get<IUsers.UserRating>(getUserUrl('/me'))
    return response
  },

  async resetPassword(data: IUsers.IResetPasswordForm) {
    const { lang } = data
    const response = await axiosInstance.post<IUsers.UserRating>(
      getUserUrl(`/user/password/reset?lang=${lang}`),
      toFormData(data.form)
    )
    return response
  },

  async sendCodeResetPassword(data: IUsers.ICodeResetPassword) {
    const response = await axiosInstance.patch<IUsers.UserRating>(
      getUserUrl('/user/password/reset/code'),
      toFormData(data)
    )
    return response
  }
}

export default AuthService
