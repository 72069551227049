import { FC } from 'react'

import clsx from 'clsx'
import Select from 'react-select'

import { useIsMobile, useTranslationField } from '@/shared/hooks'

import { TModalModifers } from '../../modal/modal.type'
import { ConnectSelect } from '../form-connect'
import { IPropsSelect, TModifiersSelect, TPropsComponent } from '../form.interface'
import { ReactSelectMobile } from '../select-react-mobile'

import styles from './Select.module.scss'

interface IMyOptionsProps extends TPropsComponent {
  mod: TModifiersSelect
}
const MyOption = (props: IMyOptionsProps) => {
  const { innerProps, innerRef, isSelected, mod } = props
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={clsx(
        styles.option,
        isSelected && styles.active,
        mod.inherit && styles.inherit,
        mod.simple && styles.simple
      )}>
      {!mod.simple && <span className={styles.check} />}

      {props.data.icon && (
        <img className={styles.icon} src={props.data.icon} alt={props.data.label} />
      )}
      {props.data.prev && <span>{props.data.prev}</span>}

      <div className={styles.name}>{props.data.label} </div>
    </div>
  )
}

export const ReactSelect: FC<IPropsSelect> = ({
  selectRef,
  value,
  onChange,
  options,
  connect,
  defaultValue,
  name,
  isDisabled,
  containerClassName,
  registerConfig,
  isSearchable,
  modifier,
  placeholder,
  isCode,
  type,
  scroll,
  isBet
}) => {
  const { getFieldStatic } = useTranslationField()
  const { isMobile } = useIsMobile()
  const SelectComponent = connect ? ConnectSelect : Select

  const mod = {
    inherit: false,
    isHigh: false,
    simple: false
  }

  modifier?.forEach(item => {
    mod[item] = true
  })

  const modifers = ['simple'] as TModalModifers[]

  return (
    <div
      className={clsx(
        styles.wrapper,
        mod.simple && styles.simple,
        mod.isHigh && styles.isHigh,
        isCode && styles.code,
        isDisabled && styles.disabled,
        containerClassName
      )}>
      {isMobile ? (
        <ReactSelectMobile
          options={options}
          name={name}
          placeholder={placeholder}
          connect={connect}
          containerClassName={containerClassName}
          type={type}
          scroll={scroll}
          isBet={isBet}
        />
      ) : (
        <SelectComponent
          value={value}
          isDisabled={isDisabled}
          defaultValue={defaultValue}
          options={options}
          ref={selectRef}
          classNamePrefix={'react-select'}
          onChange={onChange}
          noOptionsMessage={() => getFieldStatic('not_found')}
          name={name}
          placeholder={placeholder}
          isSearchable={isSearchable}
          components={{
            IndicatorSeparator: () => null,
            Option: props => <MyOption {...props} mod={mod} />
          }}
          {...(connect
            ? {
                registerConfig
              }
            : {})}
        />
      )}
    </div>
  )
}
