import React, { FC, PropsWithChildren, useState } from 'react'

import clsx from 'clsx'
import { IoIosArrowDown, IoIosArrowForward, IoIosArrowUp } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

import { useIntroTooltipStore } from '@/app/zustand-store'

import { useTranslationField } from '@/shared/hooks'

import styles from './Accordion.module.scss'

interface IProps extends PropsWithChildren {
  name: string
  isLink?: boolean
  isActive?: boolean
  link?: string
  closeMenu?: () => void
}

export const Accordion: FC<IProps> = ({ closeMenu, name, isLink, isActive, children, link }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { getFieldStatic } = useTranslationField()
  const { activeTooltip, active } = useIntroTooltipStore()

  const navigate = useNavigate()

  const withTooltip = getFieldStatic(activeTooltip.name) === name && active

  const onClickHeader = () => {
    if (withTooltip) return null
    if (link) {
      navigate(link)
    } else {
      setIsOpen(prev => !prev)
    }
  }

  return (
    <div
      className={clsx(
        styles.wrapper,
        isOpen && styles.active,
        withTooltip && styles.activeTooltipWrapper
      )}>
      <div
        className={clsx(styles.header, isActive && styles.active)}
        onClick={isActive ? closeMenu : onClickHeader}>
        <div className={styles.name}>{name}</div>
        {!link && (
          <div className={styles.arrow}>{isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
        )}
        {isLink && <IoIosArrowForward />}
      </div>
      <div className={clsx(styles.body)}>
        <div>{children}</div>
      </div>
    </div>
  )
}
