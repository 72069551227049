// Библиотечные компоненты
import { FC, useState } from 'react'

import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { TStepAuth } from '@/app/providers/protected-router'

import { useAuthActions } from '@/features/auth/api'
import FourDigitCode from '@/features/auth/ui/FourDigitCode'

import {
  CloseGradientJSX,
  CloseIconSimpleJSX,
  LogoChiJSX,
  LogoEnJSX
} from '@/shared/assets/common/svg'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { validEmail } from '@/shared/regex'
import { IUsers } from '@/shared/types/user.types'
import { Button, FormWrapper, Input } from '@/shared/ui/form'
import { Loader } from '@/shared/ui/loader'
import { addMessageToast } from '@/shared/utils'

// Компоненты

interface IProps {
  page: (val: TStepAuth) => void
  widget?: boolean
  email: string
  localValue: string | null
}
export const ForgotPasswordCode: FC<IProps> = ({ page, widget = false, email, localValue }) => {
  const { sendCodeResetPassword } = useAuthActions()
  const navigate = useNavigate()
  const { isMobile } = useIsMobile()
  const { getFieldStatic, locale } = useTranslationField()
  const [step, setStep] = useState(0)
  const methods = useForm({
    mode: 'onBlur'
  })

  const {
    formState: { errors },
    watch,
    getValues
  } = methods

  const handlerFormData = async (values: FieldValues) => {
    const payload = {
      ...values,
      code: values.code.replaceAll(' ', '')
    }
    try {
      await sendCodeResetPassword.mutateAsync(payload as IUsers.ICodeResetPassword)
      page('login')
    } catch (error: any) {
      addMessageToast(error)
    }
  }

  if (sendCodeResetPassword.isLoading) return <Loader />

  return (
    <FormWrapper methods={methods} onSubmit={handlerFormData} className='LoginForm'>
      {locale === 'en' ? <LogoEnJSX className='logo' /> : <LogoChiJSX className='logo' />}
      <div className='formGroup'>
        <div className='close' onClick={() => (widget ? page('') : navigate(-1))}>
          {isMobile ? <CloseGradientJSX /> : <CloseIconSimpleJSX />}
        </div>
        <>
          <div className='title'>{getFieldStatic('reset_pass')}</div>
          <div className='auth-block-wrap-without-margin'>
            <Input
              mask=''
              name='email'
              type='email'
              value={watch('email')}
              connect
              placeholder={getFieldStatic('login_email')}
              errorText={errors?.email?.message}
              registerConfig={{
                required: getFieldStatic('required_field'),
                pattern: {
                  value: validEmail,
                  message: getFieldStatic('correct_email')
                }
              }}
            />
            <Input
              mask='9 9 9 9'
              name='code'
              type='code'
              connect
              placeholder={getFieldStatic('reset_code')}
              errorText={errors?.code?.message}
              registerConfig={{
                required: getFieldStatic('required_field'),
                minLength: {
                  value: 4,
                  message: getFieldStatic('minimum_characters') + ' - 4'
                }
              }}
            />
            <Input
              mask=''
              name='new_password'
              type='password'
              autoComplete='off'
              connect
              isPassword
              placeholder={getFieldStatic('login_pass_new')}
              errorText={errors?.new_password?.message}
              registerConfig={{
                required: getFieldStatic('required_field'),
                minLength: {
                  value: 8,
                  message: getFieldStatic('minimum_characters') + ' - 8'
                }
              }}
            />
            <Input
              mask=''
              name='repeat_password'
              type='password'
              connect
              isPassword
              placeholder={getFieldStatic('login_pass_repeat')}
              errorText={errors?.repeat_password?.message}
              registerConfig={{
                required: getFieldStatic('required_field'),
                minLength: {
                  value: 8,
                  message: getFieldStatic('minimum_characters') + ' - 8'
                },
                validate: value =>
                  (value as any) === getValues().new_password ||
                  getFieldStatic('passwords_dont_match')
              }}
            />
            <Button
              modifiers={['red']}
              classNameContainer='buttonContainer'
              type='submit'
              disabled={sendCodeResetPassword.isLoading}>
              {getFieldStatic('reset')}
            </Button>
          </div>
        </>
      </div>
    </FormWrapper>
  )
}
