import { FC, useEffect, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { BasketBets } from '@/widgets/basket/basket.interface'
import { submitAllOdinarBet, useBasket } from '@/widgets/basket/useBasket'

import { BasketFooter } from '@/features/sports/bets-basket/tab-content/footer-content'

import SingleBet from '@/entities/sports/bets-basket/single-bet/desktop-single-bet'

import { useErrorAlert } from '@/shared/hooks/useErrorAlert'
import { BetCheckbox } from '@/shared/sports/bet-checkbox'

import styles from './SinglesTabContent.module.scss'

const SinglesTabContent: FC = () => {
  const cart = useBasket()
  const { addErrorAlert } = useErrorAlert()
  const queryClient = useQueryClient()
  const successCallback = () => {
    queryClient.invalidateQueries(['get user data'])
  }

  const [stake, setStake] = useState<number>(0)
  const [betList, setBetList] = useState<{ [x: string | number]: number }>({})
  const [submitCart, setSubmitCart] = useState<Array<BasketBets.Basket>>(cart)

  useEffect(() => {
    const main_const_sport_event_id = cart.map(item => item.main_const_sport_event_id)
    const res = Object.entries(betList).reduce((acc, [key, value]) => {
      if (main_const_sport_event_id.includes(Number(key))) {
        return { ...acc, [key]: value }
      }
      return acc
    }, {})

    setBetList(res)
  }, [cart])

  const handleStake = () => {
    let stake_inputs = 0
    Object.values(betList).map((item, index) => {
      const val = Number.parseInt(String(item))
      if (!isNaN(val)) {
        stake_inputs = stake_inputs + val
        setSubmitCart(prevState => {
          if (prevState[index]) {
            prevState[index].stake = val
          } else {
          }
          return [...prevState]
        })
      }
    })
    setStake(stake_inputs)
  }

  useEffect(() => {
    setSubmitCart(cart)
    setTimeout(handleStake, 500)
  }, [cart, betList])

  useEffect(() => {
    window.addEventListener('stake', handleStake)
    return () => {
      window.removeEventListener('stake', handleStake)
    }
  })

  return (
    <>
      <div className={styles.bets}>
        {cart.map((el, index) => (
          <div key={index} className={styles.element}>
            <SingleBet bet={el} betValue={betList[el.main_const_sport_event_id] || 500} isSingle />
            <BetCheckbox
              setBetList={setBetList}
              betValue={betList[el.main_const_sport_event_id]}
              coefficient={el.market.market}
              main_const_sport_event_id={el.main_const_sport_event_id}
            />
          </div>
        ))}
      </div>
      <BasketFooter
        onSubmitBet={() => submitAllOdinarBet(submitCart, addErrorAlert, successCallback)}
        isSingle={true}
      />
    </>
  )
}

export default SinglesTabContent
