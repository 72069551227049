import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'

import { FileDrop } from 'react-file-drop'

import { AvatarDotsJSX } from '@/shared/assets/common/svg'
import { useConvertImageToWebP, useImageToBase64, useTranslationField } from '@/shared/hooks'
import { IPropsImage } from '@/shared/ui/form/form.interface'

import styles from './ImageInputRound.module.scss'

export const ImageInputRoundDefault: FC<IPropsImage> = ({
  img,
  label,
  name,
  required,
  imageRef,
  onChange
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const [fileBase64, setFileBase64] = useState<string | undefined>(undefined)
  const { getFieldStatic } = useTranslationField()
  const { convert } = useConvertImageToWebP()
  const { imageToBase64 } = useImageToBase64()

  const onDrop = (files: FileList | null) => {
    if (!files || !files[0]) return null
    let input = ref.current
    if (input) {
      input.files = files
    }
  }

  const onChangeInput = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (!files || !files[0]) return null

    if (files && files.length > 0) {
      const webpFile = await convert(files[0], 3)
      onChange?.(webpFile)
      const base64 = await imageToBase64(webpFile)
      setFileBase64(base64)
    }
  }

  const onClickImitation = () => {
    const elem = document.getElementById('file-upload')

    if (elem) {
      elem.click()
    }
  }

  useEffect(() => {}, [fileBase64])

  const letter = label.trim()[0]

  return (
    <div className={styles.wrapper}>
      <FileDrop onDrop={onDrop}>
        <input
          className={styles.input}
          type='file'
          id='file-upload'
          ref={imageRef}
          name={name}
          accept={'image/jpeg, image/png, image/webp, image/gif'}
          value={''}
          required={required}
          onChange={onChangeInput}
        />
      </FileDrop>
      <div className={styles.imageWrapper} onClick={onClickImitation}>
        <div className={styles.image}>
          <AvatarDotsJSX />
          {fileBase64 !== undefined ? (
            <img src={fileBase64} alt={'img'} />
          ) : img ? (
            <img src={img} alt={'img'} />
          ) : (
            <div className={styles.letter}>{letter}</div>
          )}
        </div>
      </div>
      <div className={styles.label}>
        {fileBase64 || img ? getFieldStatic('change_avatar') : getFieldStatic('add_avatar')}
      </div>
    </div>
  )
}
