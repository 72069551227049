import { FC, useEffect, useState } from 'react'

import clsx from 'clsx'
import { IoIosArrowUp } from 'react-icons/io'

import { useStatusBet } from '@/features/profile/ui/betting-history-table/hooks'

import { useTranslationField } from '@/shared/hooks'
import { ISport } from '@/shared/types/sport.types'
import { dateFormatter } from '@/shared/utils'

import { BettingCoefficient } from '../betting-coefficient'

import styles from './BettingCard.module.scss'
import { MoreInfo } from './slices'

interface IProps {
  data: ISport.IExpressList
  isInBasket?: boolean
}

export const BettingCard: FC<IProps> = ({ data, isInBasket }) => {
  const isExpress = Array.isArray(data.game_list)
  const defaultSelect = Array.isArray(data.game_list) ? data.game_list[0] : data

  const [selected, setSelected] = useState(defaultSelect)
  const { getFieldStatic, getField, locale } = useTranslationField()
  const [isOpen, setIsOpen] = useState(false)
  const { getStatus } = useStatusBet()

  const onOpenDetails = () => {
    setIsOpen(prev => !prev)
  }

  useEffect(() => {
    if (!isOpen) {
      setSelected(selected)
    }
  }, [isOpen])

  const status = getStatus(data)

  return (
    <div className={isInBasket ? styles.wrapperInBasket : styles.wrapper}>
      <div className={styles.body}>
        <div className={styles.content}>
          <div className={styles.row} onClick={onOpenDetails}>
            <div className={styles.columnName}>{getFieldStatic('name')}</div>
            <div className={styles.value}>
              {Array.isArray(data.game_list)
                ? `${getFieldStatic('express_game_title')} (${data.game_list.length})`
                : getField(data.game_list, 'tournament_name')}
            </div>
          </div>
          <div className={clsx(styles.row, styles.right)}>
            <div className={clsx(styles.grayText)}>{dateFormatter(data.createdAt, locale)}</div>
          </div>
        </div>

        <span className={clsx(styles.arrow, isOpen && styles.rotate)} onClick={onOpenDetails}>
          <IoIosArrowUp />
        </span>
      </div>
      {isOpen && Array.isArray(data.game_list) && (
        <div className={styles.slider}>
          {data.game_list.map(item => (
            <div
              onClick={() => setSelected(item)}
              className={clsx(styles.game, selected.id === item.id && styles.active)}>{`${getField(
              item,
              'opponent_one_name'
            )} - ${getField(item, 'opponent_two_name')}`}</div>
          ))}
        </div>
      )}

      <MoreInfo data={selected} isOpen={isOpen} />

      {!isOpen && (
        <>
          <BettingCoefficient coeff={+data.total_kef} status={status} isMobi />
        </>
      )}
    </div>
  )
}
