import { create } from 'zustand'

interface BasketState {
  isOpen: boolean
  isSettings: boolean
  changeIsSettings: (isSettings: boolean) => void
  changeIsOpen: (isOpen: boolean) => void
  increaseStep: number
  decreaseStep: number
  changeIncreaseStep: (increaseStep: number) => void
  changeDecreaseStep: (decreaseStep: number) => void
}

export const useBasketStore = create<BasketState>(set => ({
  isOpen: false,
  isSettings: false,
  changeIsSettings: isSettings => set(state => ({ isSettings: isSettings })),
  changeIsOpen: isOpen => set(state => ({ isOpen: isOpen })),
  increaseStep: 500,
  decreaseStep: 100,
  changeIncreaseStep: increaseStep => set(state => ({ increaseStep: increaseStep })),
  changeDecreaseStep: decreaseStep => set(state => ({ decreaseStep: decreaseStep }))
}))
