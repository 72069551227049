import { ReactNode } from 'react'

import {
  AllSportsGoldJSX,
  AllSportsJSX,
  AmericanFootballSportGoldJSX,
  AmericanFootballSportJSX,
  BadmintonSportGoldJSX,
  BadmintonSportJSX,
  BaseballSportGoldJSX,
  BaseballSportJSX,
  BasketballSportGoldJSX,
  BasketballSportJSX,
  BeachSoccerSportGoldJSX,
  BeachSoccerSportJSX,
  BeachVolleyballSportGoldJSX,
  BeachVolleyballSportJSX,
  BoxingSportGoldJSX,
  BoxingSportJSX,
  ChessSportGoldJSX,
  ChessSportJSX,
  DartsSportGoldJSX,
  DartsSportJSX,
  EsportsSportGoldJSX,
  EsportsSportJSX,
  FootballSportGoldJSX,
  FootballSportJSX,
  FutsalSportGoldJSX,
  FutsalSportJSX,
  HandballSportGoldJSX,
  HandballSportJSX,
  HorseRacingSportGoldJSX,
  HorseRacingSportJSX,
  IceHockeySportGoldJSX,
  IceHockeySportJSX,
  RugbySportGoldJSX,
  RugbySportJSX,
  SnookerSportGoldJSX,
  SnookerSportJSX,
  TableTennisSportGoldJSX,
  TableTennisSportJSX,
  TennisSportGoldJSX,
  TennisSportJSX,
  VolleyballSportGoldJSX,
  VolleyballSportJSX,
  WaterPoloSportGoldJSX,
  WaterPoloSportJSX
} from '@/shared/assets/sport/svg'

import {
  forcast_american_football_bg,
  forcast_badminton_bg,
  forcast_baseball_bg,
  forcast_basketball_bg,
  forcast_beach_soccer_bg,
  forcast_beach_volleyball_bg,
  forcast_boxing_bg,
  forcast_chess_bg,
  forcast_darts_bg,
  forcast_esports_bg,
  forcast_football_bg,
  forcast_futsal_bg,
  forcast_handball_bg,
  forcast_hockey_bg,
  forcast_horse_racing_bg,
  forcast_rugby_bg,
  forcast_snooker_bg,
  forcast_table_tennis_bg,
  forcast_tennis_bg,
  forcast_volleball_bg,
  forcast_water_polo_bg
} from '../assets/experts/forcast-background-img'

type SportParams = {
  bg: string
  color: string
  svg?: any
  svgGold?: any
}

export const useGetSportBGAndColor = (sportName: string) => {
  const sportHelper: Record<string, SportParams> = {
    'All category': {
      bg: forcast_football_bg,
      color: '#4A6937',
      svg: <AllSportsJSX />,
      svgGold: <AllSportsGoldJSX />
    },
    Football: {
      bg: forcast_football_bg,
      color: '#4A6937',
      svg: <FootballSportJSX />,
      svgGold: <FootballSportGoldJSX />
    },
    'Ice Hockey': {
      bg: forcast_hockey_bg,
      color: '#435C69',
      svg: <IceHockeySportJSX />,
      svgGold: <IceHockeySportGoldJSX />
    },
    Basketball: {
      bg: forcast_basketball_bg,
      color: '#623A2D',
      svg: <BasketballSportJSX />,
      svgGold: <BasketballSportGoldJSX />
    },
    Tennis: {
      bg: forcast_tennis_bg,
      color: '#A56820',
      svg: <TennisSportJSX />,
      svgGold: <TennisSportGoldJSX />
    },
    Baseball: {
      bg: forcast_baseball_bg,
      color: '#805F3A',
      svg: <BaseballSportJSX />,
      svgGold: <BaseballSportGoldJSX />
    },
    Volleyball: {
      bg: forcast_volleball_bg,
      color: '#A66820',
      svg: <VolleyballSportJSX />,
      svgGold: <VolleyballSportGoldJSX />
    },
    Rugby: {
      bg: forcast_rugby_bg,
      color: '#4C5F49',
      svg: <RugbySportJSX />,
      svgGold: <RugbySportGoldJSX />
    },
    Handball: {
      bg: forcast_handball_bg,
      color: '#805F3A',
      svg: <HandballSportJSX />,
      svgGold: <HandballSportGoldJSX />
    },
    Boxing: {
      bg: forcast_boxing_bg,
      color: '#8F4D4F',
      svg: <BoxingSportJSX />,
      svgGold: <BoxingSportGoldJSX />
    },
    'Table Tennis': {
      bg: forcast_table_tennis_bg,
      color: '#456466',
      svg: <TableTennisSportJSX />,
      svgGold: <TableTennisSportGoldJSX />
    },
    Chess: {
      bg: forcast_chess_bg,
      color: '#4E434E',
      svg: <ChessSportJSX />,
      svgGold: <ChessSportGoldJSX />
    },
    'American Football': {
      bg: forcast_american_football_bg,
      color: '#4C5F49',
      svg: <AmericanFootballSportJSX />,
      svgGold: <AmericanFootballSportGoldJSX />
    },
    Futsal: {
      bg: forcast_futsal_bg,
      color: '#4A6937',
      svg: <FutsalSportJSX />,
      svgGold: <FutsalSportGoldJSX />
    },
    Badminton: {
      bg: forcast_badminton_bg,
      color: '#6B0000',
      svg: <BadmintonSportJSX />,
      svgGold: <BadmintonSportGoldJSX />
    },
    Darts: {
      bg: forcast_darts_bg,
      color: '#456263',
      svg: <DartsSportJSX />,
      svgGold: <DartsSportGoldJSX />
    },
    'Water Polo': {
      bg: forcast_water_polo_bg,
      color: '#535B6D',
      svg: <WaterPoloSportJSX />,
      svgGold: <WaterPoloSportGoldJSX />
    },
    'Beach Soccer': {
      bg: forcast_beach_soccer_bg,
      color: '#4A6937',
      svg: <BeachSoccerSportJSX />,
      svgGold: <BeachSoccerSportGoldJSX />
    },
    'Beach Volleyball': {
      bg: forcast_beach_volleyball_bg,
      color: '#A66820',
      svg: <BeachVolleyballSportJSX />,
      svgGold: <BeachVolleyballSportGoldJSX />
    },
    Snooker: {
      bg: forcast_snooker_bg,
      color: '#446263',
      svg: <SnookerSportJSX />,
      svgGold: <SnookerSportGoldJSX />
    },
    Esports: {
      bg: forcast_esports_bg,
      color: '#4E434E',
      svg: <EsportsSportJSX />,
      svgGold: <EsportsSportGoldJSX />
    },
    'Horse Racing': {
      bg: forcast_horse_racing_bg,
      color: '#623A2D',
      svg: <HorseRacingSportJSX />,
      svgGold: <HorseRacingSportGoldJSX />
    },
    'Martial Arts': {
      bg: forcast_boxing_bg,
      color: '#8F4D4F',
      svg: <BoxingSportJSX />,
      svgGold: <BoxingSportGoldJSX />
    }
  }

  const getSportColor = (): string => {
    return sportHelper[sportName]?.color || '#4A6937'
  }

  const getSportBackground = () => {
    return sportHelper[sportName]?.bg || forcast_football_bg
  }

  const getSportSvg = (): ReactNode => {
    return sportHelper[sportName]?.svg || AllSportsJSX
  }

  const getSportActiveSvg = (): ReactNode => {
    return sportHelper[sportName]?.svgGold || AllSportsGoldJSX
  }

  return {
    sportHelper,
    getSportColor,
    getSportBackground,
    getSportSvg,
    getSportActiveSvg
  }
}
