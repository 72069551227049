import { useBasketHistory } from '@/features/basket/api'

import { HistoryBet } from '@/entities/sports/bets-basket/single-bet/history-bet'

import { ISport } from '@/shared/types/sport.types'
import { Loader } from '@/shared/ui/loader'

import styles from '../HistoryContent.module.scss'

export const ActiveHistory = () => {
  const { ref, items, isFetching } = useBasketHistory({})

  return (
    <div className={styles.wrapper}>
      {items?.map(el => {
        if (Array.isArray(el.game_list)) {
          return el.game_list.map(elem => (
            <HistoryBet bet={elem as ISport.IBet} key={elem.id} amount={el.amount} />
          ))
        }
        return <HistoryBet bet={el.game_list as ISport.IBet} key={el.id} amount={el.amount} />
      })}
      <div ref={ref} style={{ minHeight: '20px' }} />
      {isFetching && <Loader isMini />}
    </div>
  )
}
