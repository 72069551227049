import { FC, useState } from 'react'

import clsx from 'clsx'
import ReactTextareaAutosize from 'react-textarea-autosize'

import { HidePassIcon, ShowPassIcon } from '@/shared/assets/common/svg'

import { ConnectedTextArea } from '../form-connect'
import { ITextArea } from '../form.interface'

import styles from './TextArea.module.scss'

const RegularTextArea = (props: ITextArea) => <ReactTextareaAutosize {...props} />

export const TextArea: FC<ITextArea> = ({
  className,
  classNameErrorWrapper,
  classNameError,
  containerClassName,
  disabled,
  onChange,
  placeholder,
  type,
  value,
  defaultValue,
  checked,
  id,
  name,
  required,
  errorText,
  connect,
  registerConfig,
  icon,
  isPassword,
  style = {},
  onKeyDownHandler,
  ...inputProps
}) => {
  const [isViewPassword, setViewPassword] = useState(false)

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault() // Предотвращаем перенос строки в textarea
      onKeyDownHandler?.()
    }
  }
  const TextAreaComponent: FC<ITextArea> = connect ? ConnectedTextArea : RegularTextArea

  return (
    <div className={clsx(styles.wrapper, containerClassName)}>
      <TextAreaComponent
        className={clsx(
          className,
          styles.input,
          disabled && styles.disabled,
          errorText && styles.inputError && classNameErrorWrapper
        )}
        type={isViewPassword ? 'text' : type || 'text'}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        checked={checked || false}
        id={id}
        name={name}
        disabled={disabled}
        required={required || false}
        defaultValue={defaultValue}
        style={style}
        onKeyDown={onKeyDown}
        {...(connect
          ? {
              registerConfig
            }
          : {})}
        {...inputProps}
      />
      {required && <div className={styles.required}></div>}
      {icon && <div className={styles.icon}>{icon}</div>}
      {isPassword && (
        <div
          className={styles.glassWrapper}
          onClick={() => {
            setViewPassword(prev => !prev)
          }}>
          {isViewPassword ? <ShowPassIcon /> : <HidePassIcon />}
        </div>
      )}
      {errorText && typeof errorText === 'string' && (
        <div className={clsx(styles.error, classNameError)}>
          <span>{errorText}</span>
        </div>
      )}
    </div>
  )
}
