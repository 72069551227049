import React from 'react'

import { useTranslationField } from '@/shared/hooks'
import { ISport } from '@/shared/types/sport.types'
import { getMessageLocality } from '@/shared/utils'

export type TStatusBet =
  | 'progress'
  | 'win_half_1'
  | 'win_half_2'
  | 'lose_half_1'
  | 'lose_half_2'
  | 'refund_error'
  | 'refund'
  | 'won'
  | 'loss'

const array = ['Closed', 'True', 'False', 'None']

export const isWinHalf = ['(True, None)', '(None, True)']
export const isLoseHalf = ['(False, None)', '(None, False)']
const isAllStatus = [...array, ...isWinHalf, ...isLoseHalf]
export const useStatusBet = () => {
  const { getFieldStatic } = useTranslationField()

  function getStatus(bet: ISport.IExpressList): TStatusBet {
    if (!isAllStatus.includes(bet.status)) {
      return 'progress'
    }

    if (bet.status === isWinHalf[0]) return 'win_half_1'
    if (bet.status === isWinHalf[1]) return 'win_half_2'

    if (bet.status === isLoseHalf[0]) return 'lose_half_1'
    if (bet.status === isLoseHalf[1]) return 'lose_half_2'

    //Возврат из-за ошибки
    if (bet.score === 'MoneyBack') return 'refund_error'

    if (bet.score && bet.status === 'None') return 'refund'

    return bet.isWin ? 'won' : 'loss'
  }

  function getBetStatus(bet: ISport.IBet) {
    if (!isAllStatus.includes(bet.status)) {
      return 'progress'
    }
    if (bet.status === isWinHalf[0]) return 'win_half_1'
    if (bet.status === isWinHalf[1]) return 'win_half_2'

    if (bet.status === isLoseHalf[0]) return 'lose_half_1'
    if (bet.status === isLoseHalf[1]) return 'lose_half_2'

    if (bet.score === 'MoneyBack') {
      return 'refund_error'
    }
    if (bet.score && bet.status === 'None') return 'refund'

    return bet.status === 'True' ? 'won' : 'loss'
  }

  function getBetMessage(bet: ISport.IExpressList) {
    if (!isAllStatus.includes(bet.status)) {
      return getFieldStatic('BET_IN_PROGRESS')
    }

    if ([...isWinHalf, ...isLoseHalf].includes(bet.status)) return getFieldStatic('HALF_REFUND')

    if (bet.score === 'MoneyBack') return getFieldStatic('BET_IS_REFUND_ERROR')

    if (bet.score && bet.status === 'None') return getFieldStatic('BET_IS_RETURN')

    return bet.isWin ? getFieldStatic('BET_IS_WIN') : getFieldStatic('BET_IS_LOSE')
  }

  function getBetMessageExpress(bet: ISport.IBet) {
    if (!isAllStatus.includes(bet.status)) {
      return getFieldStatic('BET_IN_PROGRESS')
    }

    if ([...isWinHalf, ...isLoseHalf].includes(bet.status)) return getFieldStatic('HALF_REFUND')

    if (bet.score === 'MoneyBack') {
      return getFieldStatic('BET_IS_REFUND_ERROR')
    }
    if (bet.score && bet.status === 'None') return getFieldStatic('BET_IS_RETURN')

    return bet.status === 'True' ? getFieldStatic('BET_IS_WIN') : getFieldStatic('BET_IS_LOSE')
  }

  return { getStatus, getBetStatus, getBetMessage, getBetMessageExpress }
}
