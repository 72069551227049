import { FC } from 'react'

import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { IGames } from '@/shared/types/games.types'

import styles from './CardSlot.module.scss'

type Props = IGames.IGameData & { inSlider?: boolean }

export const CardSlot: FC<Props> = ({ id, image_webp, game_name, inSlider }) => {
  return (
    <Link to={`/games/item/${id}`} replace={true} reloadDocument={true}>
      <div className={clsx(styles.cardSlotItem, inSlider && styles.inSlider)}>
        <img src={image_webp} alt='' />
        <div className={styles.title}>{game_name}</div>
      </div>
    </Link>
  )
}
