import { FC, useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { Navigation } from 'swiper/modules'

import { CardSlot } from '@/widgets/games/games-list/ui'

import { useGameList, useGameSession } from '@/features/games/games-list/api'
import { Slider } from '@/features/slider'

import { useHandlerIframe } from '@/entities/game/hooks'

import { CloseFullScreenJSX, OpenFullScreenJSX } from '@/shared/assets/games/svg'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { useGetScreenResolution } from '@/shared/hooks/useGetScreenResolution'
import { useAppSelector } from '@/shared/model'
import { IGames } from '@/shared/types/games.types'
import { Loader } from '@/shared/ui/loader'

import styles from './GameWindow.module.scss'

type ElementProps = {
  item: IGames.IGameData
}

export const GameWindow = () => {
  const { locale } = useAppSelector(state => state.defaultReducer)
  const { getFieldStatic } = useTranslationField()
  const { refFrame } = useHandlerIframe()
  const { game_id } = useParams()
  const [heightIframe, setHeightIframe] = useState<number>()
  const { width } = useGetScreenResolution()
  const { isMobile } = useIsMobile()
  const [isFullScreenOpen, setIsFullScreenOpen] = useState(false)

  const { data, isLoading, isError } = useGameSession({
    game_id: Number(game_id),
    lang: locale.toUpperCase()
  })

  const { games } = useGameList(20)

  const Element: FC<ElementProps> = ({ item }) => {
    return <CardSlot key={item.id} {...item} inSlider />
  }

  const iframeElement = refFrame.current

  const getHeaderHeight = () => 0 // Совокупная высота элементов расположеных выше iframe
  const getDefaultIFrameHeight = () => globalThis.innerHeight - getHeaderHeight()
  const getScrollTop = () => (window.scrollY >= 0 ? window.scrollY : 0)
  const sendPageAttributes = () => {
    iframeElement?.contentWindow?.postMessage({
      type: 'GPWebAppSetPageAttributes',
      data: {
        scrollX: document.documentElement.scrollLeft,
        scrollY: getScrollTop(),
        clientHeight: document.documentElement.clientHeight,
        clientWidth: document.documentElement.clientWidth,
        scrollFromTop: getScrollTop(),
        availableFrameHeight: getDefaultIFrameHeight()
      }
    })
  }

  const updateFrameHeight = (withScroll = false) => {
    const iframeHeight = getDefaultIFrameHeight()
    const updateIframeHeight = withScroll
      ? String(getScrollTop() + iframeHeight)
      : String(iframeHeight)
    if (iframeElement) {
      iframeElement.height = updateIframeHeight
    }
    setHeightIframe(Number(updateIframeHeight))

    sendPageAttributes()
  }

  const listenerMessage = (event: MessageEvent<any>) => {
    const { channel } = event.data

    if (channel === 'GPWebAppBridge') {
      const { type, data } = event.data

      if (type === 'GPWebAppInit') {
        iframeElement?.contentWindow?.postMessage(
          {
            type: 'SetSupportedMethods',
            data: ['GPWebAppSetIframeHeight']
          },
          '*'
        )

        updateFrameHeight()

        iframeElement?.contentWindow?.postMessage(
          {
            type: 'GPWebAppInitResult',
            data: {
              result: true,
              requestId: data.requestId
            }
          },
          '*'
        )
      } else if (type === 'GPWebAppSetIframeHeight') {
        if (data.height === 0) {
          updateFrameHeight(true)
        } else if (data.height > 0 && iframeElement) {
          iframeElement.height = data.height
          sendPageAttributes()
        }

        iframeElement?.contentWindow?.postMessage(
          {
            type: 'GPWebAppSetIframeHeightResult',
            data: {
              result: true,
              requestId: data.requestId
            }
          },
          '*'
        )
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', listenerMessage)
    if (iframeElement) {
      window.scrollTo(0, 0)
    }
    return () => {
      window.removeEventListener('message', listenerMessage)
    }
  }, [iframeElement, width])

  return (
    <div className={styles.wrapper}>
      {!isMobile && <div className={styles.title}>Game</div>}
      {isLoading ? (
        <Loader />
      ) : isError ? (
        getFieldStatic('error')
      ) : !data?.uri ? (
        getFieldStatic('not_found')
      ) : null}
      {!isError && (
        <>
          <iframe
            className={isFullScreenOpen ? styles.iframeFullScreen : styles.iframe}
            ref={refFrame}
            width={'100%'}
            frameBorder='0'
            src={data?.uri}
            title='game'
          />
          {!isMobile && (
            <div
              className={isFullScreenOpen ? styles.closeFullScreen : styles.openFullScreen}
              onClick={() => setIsFullScreenOpen(!isFullScreenOpen)}>
              {isFullScreenOpen ? <CloseFullScreenJSX /> : <OpenFullScreenJSX />}
            </div>
          )}
        </>
      )}

      <div className={styles.slider}>
        <div className={styles.moreGames}>{getFieldStatic('more_games')}</div>
        {games && (
          <Slider
            slidesPerShow={isMobile ? 1.1 : 4}
            classNameSwiperSlide={styles.swiperSlide}
            className={styles.swiper}
            classNameButtons={styles.swiperButtons}
            data={games}
            Element={Element}
            direction='horizontal'
            modules={[Navigation]}
            isInList
          />
        )}
      </div>
    </div>
  )
}
