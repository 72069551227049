import { FC } from 'react'

import { setLang } from '@/app/store/actions/defaultActions'

import { QuizLayout } from '@/features/quiz/ui/layout'

import { QuizContent } from '@/entities/quiz-content'

import { useLocalStorage } from '@/shared/hooks/useLocalStorage'
import { useAppDispatch } from '@/shared/model'
import { Button } from '@/shared/ui/form'

import { backgroundQuiz_1 } from '@/Assets/img/quiz'

import { TQuiz } from '../..'

import styles from './lang.module.scss'

export const QuizLang: FC<TQuiz> = ({ onNextStep }) => {
  const dispatch = useAppDispatch()

  const { setLocalStorageValue } = useLocalStorage('locale')

  const onChangeLang = (lang: string) => {
    setLocalStorageValue(lang)
    dispatch(setLang(lang))

    document.documentElement.lang = lang
    onNextStep?.()
  }
  return (
    <QuizLayout background={backgroundQuiz_1}>
      <div
        style={{
          margin: '0 auto',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          overflow: 'hidden'
        }}>
        <QuizContent
          title={'Choose your language'}
          title2={'选择你的语言'}
          maxWidth='750px'
          onNextStep={onNextStep}
          classNameWrapper={styles.wrapper}
          buttonsJSX={
            <>
              <Button modifiers={['red']} onClick={() => onChangeLang('en')}>
                en
              </Button>
              <Button modifiers={['red']} onClick={() => onChangeLang('cn')}>
                中文
              </Button>
            </>
          }
        />
        <div className={styles.images} />
      </div>
    </QuizLayout>
  )
}
