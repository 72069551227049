import { FC, useEffect, useState } from 'react'

import clsx from 'clsx'
import { GoEye, GoEyeClosed } from 'react-icons/go'
import { IoMdClose } from 'react-icons/io'
import InputMask from 'react-input-mask'
import ClipLoader from 'react-spinners/ClipLoader'

import { EditIconJSX } from '@/shared/assets/common/svg'

import { ConnectedInput } from '../form-connect'
import { IPropInput } from '../form.interface'

import styles from './Input.module.scss'

const RegularInput = (props: IPropInput) => {
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onBlur?.(event)
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onFocus?.(event)
  }

  return <InputMask {...props} mask={props.mask || ''} onBlur={handleBlur} onFocus={handleFocus} />
}

export const Input: FC<IPropInput> = ({
  className,
  containerClassName,
  disabled,
  onChange,
  placeholder,
  type,
  value,
  defaultValue,
  checked,
  id,
  name,
  required,
  errorText,
  connect,
  mask = '',
  maskChar,
  registerConfig,
  icon,
  isPassword,
  iconStart,
  isClear,
  methods,
  style = {},
  isEdit,
  onClickIcon,
  isLoading,
  autoComplete,
  ...inputProps
}) => {
  const [isViewPassword, setViewPassword] = useState(false)
  const [focused, setFocused] = useState(false)

  const InputComponent: FC<IPropInput> = connect ? ConnectedInput : RegularInput

  useEffect(() => {
    const handleBlur = (event: FocusEvent) => {
      if ((event.target as HTMLElement).getAttribute('name') === name) {
        setFocused(false)
      }
    }

    const handleFocus = (event: FocusEvent) => {
      if ((event.target as HTMLElement).getAttribute('name') === name) {
        setFocused(true)
      }
    }

    document.addEventListener('focus', handleFocus, true)
    document.addEventListener('blur', handleBlur, true)

    return () => {
      document.removeEventListener('focus', handleFocus, true)
      document.removeEventListener('blur', handleBlur, true)
    }
  }, [name])

  const [innerErrorText, setInnerErrorText] = useState(errorText)

  return (
    <div className={clsx(styles.wrapper, containerClassName, focused && styles.focused)}>
      <InputComponent
        className={clsx(
          className,
          styles.input,
          disabled && styles.disabled,
          iconStart && styles.iconStart,
          errorText && !focused && styles.inputError,
          !isViewPassword && type === 'password' && styles.inputPassTextHidden
        )}
        type={isViewPassword ? 'text' : type || 'text'}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={() => {
          setFocused(true)
          setInnerErrorText(undefined)
        }}
        onBlur={() => {
          setFocused(false)
        }}
        value={value}
        checked={checked || false}
        id={id}
        name={name}
        disabled={disabled}
        autoComplete={autoComplete}
        mask={mask}
        required={required || false}
        defaultValue={defaultValue}
        maskChar={maskChar}
        style={style}
        {...(connect
          ? {
              registerConfig
            }
          : {})}
        {...inputProps}
      />
      {required && <div className={styles.required}></div>}
      {isLoading ? (
        <>
          <div className={clsx(styles.icon, styles.editIcon)}>
            <ClipLoader size={'20px'} color='#A4A4A4' />
          </div>
        </>
      ) : (
        <>
          {isClear && !isEdit && !disabled && (value || methods?.watch(name)) && (
            <div
              className={clsx(styles.icon, styles.closeIcon)}
              onClick={() => methods?.setValue(name, '')}>
              <IoMdClose />
            </div>
          )}
          {isEdit && !disabled && (value || methods?.watch(name)) && (
            <div
              className={clsx(styles.icon, styles.editIcon, errorText && styles.disabled)}
              onClick={() => !errorText && !isLoading && onClickIcon?.()}>
              <EditIconJSX />
            </div>
          )}
          {icon && <div className={clsx(styles.icon, iconStart && styles.start)}>{icon}</div>}
        </>
      )}

      {isPassword && (
        <div
          className={styles.glassWrapper}
          onClick={() => {
            setViewPassword(prev => !prev)
          }}>
          {isViewPassword ? <GoEye /> : <GoEyeClosed />}
        </div>
      )}
      {errorText && typeof errorText === 'string' && (
        <div className={styles.error}>
          <span>{errorText}</span>
        </div>
      )}
    </div>
  )
}
