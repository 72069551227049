import { FC, useEffect, useRef } from 'react'

import { Controller } from 'react-hook-form'
import Select from 'react-select'

import { IPropsSelect } from '../form.interface'

import { ConnectForm } from './ConnectForm'

export const ConnectSelect: FC<IPropsSelect> = ({ registerConfig, ...props }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const selectRef = useRef<any>(null)

  const scrollToSelected = () => {
    setTimeout(() => {
      const selectedElement = containerRef.current?.querySelector(`[aria-selected="true"]`)
      selectedElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }, 10)
  }

  return (
    <ConnectForm>
      {({ control }) => (
        <Controller
          control={control}
          rules={registerConfig}
          name={props.name}
          render={({ field: { onChange, onBlur, ref, value } }) => {
            return (
              <div ref={containerRef}>
                <Select
                  {...props}
                  onBlur={onBlur}
                  menuShouldScrollIntoView={true}
                  onMenuOpen={scrollToSelected}
                  onChange={data => {
                    onChange(data?.value)
                  }}
                  value={props.options.find(c => c.value === value) || props.defaultValue}
                  ref={selectRef}
                  classNamePrefix='react-select'
                />
              </div>
            )
          }}
        />
      )}
    </ConnectForm>
  )
}
