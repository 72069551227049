import { FC, useState } from 'react'

import clsx from 'clsx'

import { BetValueSelector } from '@/features/sports/bets-basket/tab-content/bet-value-selector'

import { useTranslationField } from '@/shared/hooks'
import { Button, Checkbox } from '@/shared/ui/form'

import styles from './BasketFooter.module.scss'

interface IBasketFooter {
  isSingle?: boolean
  coefficient?: number
  onSubmitBet: () => void
}

export const BasketFooter: FC<IBasketFooter> = ({ isSingle, coefficient, onSubmitBet }) => {
  const [checked, setChecked] = useState(true)
  const { getFieldStatic } = useTranslationField()
  const [betValue, setBetValue] = useState(1000)

  const onChangeBetValue = (val: number) => {
    setBetValue(val)
  }

  return (
    <div className={styles.wrapper}>
      {!isSingle && (
        <>
          <BetValueSelector onChange={onChangeBetValue} betValue={betValue} />
          <div className={clsx(styles.overall, styles.row)}>
            <div>{getFieldStatic('over_all_odds')}</div>
            <div className={styles.value}>{coefficient?.toFixed(2)}</div>
          </div>
          <div className={clsx(styles.winning, styles.row)}>
            <div>{getFieldStatic('possible_win')}</div>
            <div className={styles.value}>{((coefficient || 1) * betValue).toFixed(2)}</div>
          </div>
        </>
      )}

      <Checkbox
        className={clsx(styles.checkbox, isSingle && styles.single)}
        checkboxClassName={styles.checkbox__check}
        title={getFieldStatic('accept_terms_conditions')}
        checked={checked}
        modifier={['center']}
        onChange={() => {
          setChecked(!checked)
        }}
      />
      <Button
        disabled={!checked}
        modifiers={['red']}
        classNameContainer='buttonContainer'
        onClick={onSubmitBet}>
        {getFieldStatic('place_bet')}
      </Button>
    </div>
  )
}
