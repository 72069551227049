import { FC } from 'react'

import clsx from 'clsx'

import { useIntroTooltipStore } from '@/app/zustand-store'

import { useIsMobile, useTranslationField } from '@/shared/hooks'

import styles from './HeaderTooltip.module.scss'

export const HeaderTooltip: FC = () => {
  const { activeTooltip, setNextActiveTooltip, changeActive } = useIntroTooltipStore()
  const { getFieldStatic, locale } = useTranslationField()
  const { isMobile } = useIsMobile()

  return (
    <div
      className={clsx(
        styles.wrapper,
        activeTooltip.id === 5 && isMobile && styles.wrapperShopMobile,
        activeTooltip.gifCn && !isMobile && styles.wrapperWithGif,
        activeTooltip.name === 'profile' && styles.wrapperProfile
      )}>
      <div className={styles.content}>
        {!isMobile && activeTooltip.gifCn && (
          <div className={styles.image}>
            <img src={locale === 'en' ? activeTooltip.gifEn : activeTooltip.gifCn} alt={'gif'} />
          </div>
        )}
        {activeTooltip.description && (
          <div
            className={clsx(styles.text, activeTooltip.gifCn && !isMobile && styles.textWithGif)}>
            {getFieldStatic(activeTooltip.description)}
          </div>
        )}
        <div className={styles.buttons}>
          {activeTooltip.withSkip && activeTooltip.id < (isMobile ? 5 : 6) && (
            <div className={styles.button} onClick={changeActive}>
              {getFieldStatic('skip')}
            </div>
          )}
          <div
            className={clsx(styles.button, styles.next)}
            onClick={event => {
              event.stopPropagation()
              activeTooltip.id === (isMobile ? 5 : 6) ? changeActive() : setNextActiveTooltip()
            }}>
            {activeTooltip.id === (isMobile ? 5 : 6)
              ? getFieldStatic('close')
              : getFieldStatic('next')}
          </div>
        </div>
      </div>
    </div>
  )
}
