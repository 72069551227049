import React, { FC, useEffect, useLayoutEffect, useState } from 'react'

import { useBasket } from '@/widgets/basket/useBasket'

import { BetValueSelector } from '@/features/sports/bets-basket/tab-content/bet-value-selector'

import styles from './BetCheckbox.module.scss'

interface IProps {
  coefficient: number
  className?: string
  betValue?: number
  main_const_sport_event_id: number
  setBetList: React.Dispatch<
    React.SetStateAction<{
      [x: string | number]: number
    }>
  >
}

export const BetCheckbox: FC<IProps> = ({ betValue, main_const_sport_event_id, setBetList }) => {
  const cart = useBasket()
  const [value, setValue] = useState(500)

  useEffect(() => {
    cart.find(item => {
      if (item.main_const_sport_event_id === main_const_sport_event_id) {
        if (item.stake !== undefined) setValue(item.stake)
      }
    })
  }, [cart])

  const onClick = (value: number) => {
    if (value >= 0) {
      setValue(value)
      setBetList(prev => ({ ...prev, [main_const_sport_event_id]: +value }))
    } else {
      setValue(0)
      setBetList(prev => ({ ...prev, [main_const_sport_event_id]: 0 }))
    }
    window.dispatchEvent(new Event('stake'))
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      onClick(500)
    }, 100)
  }, [])

  return (
    <div className={styles.wrapper}>
      <BetValueSelector betValue={value} onChange={onClick} />
    </div>
  )
}
