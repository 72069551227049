import { FC, useState } from 'react'

import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'

import EarnedDailyRewardModal from '@/entities/earned-daily-reward-modal'

import { CopyJSX, LogoChiJSX, LogoEnJSX } from '@/shared/assets/common/svg'
import { SuccessCheckJSX } from '@/shared/assets/home/svg'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { IUsers } from '@/shared/types/user.types'
import { Button } from '@/shared/ui/form'
import { Modal } from '@/shared/ui/modal'
import { TModalModifers } from '@/shared/ui/modal/modal.type'
import { addMessageToast } from '@/shared/utils'

import { backgroundQuiz_5 } from '@/Assets/img/quiz'

import styles from './RegisterDemoUser.module.scss'

interface IProps {
  isOpen: boolean
  data: IUsers.ResponseDemoRegister
}

export const RegisterDemoUser: FC<IProps> = ({ isOpen, data }) => {
  const navigate = useNavigate()
  const { isMobile } = useIsMobile()
  const { getFieldStatic, locale } = useTranslationField()
  type TStep = 0 | 1 | 2
  const [step, setStep] = useState<TStep>(0)
  const [copied, setCopied] = useState(false)
  const modifers = ['simple', 'tiny', 'absolute', 'deposit'] as TModalModifers[]

  const onNextStep = async (currentStep?: TStep) => {
    if (currentStep === 1) {
      setStep(currentStep)
    }
    if (currentStep === 1) {
      setStep(currentStep)
    }
    if (currentStep === 2) {
      setStep(currentStep)
    }
    if (!currentStep) {
      navigate('/')
    }
  }

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`${String(data.login_id)} ${data.password}`)
      setCopied(true)
      if (!isMobile) {
        addMessageToast(`${getFieldStatic('password_copied')}`, 'success')
      }
    } catch (err) {
      addMessageToast(getFieldStatic('error'), 'error')
    }
  }

  const EqualElement: FC = () => {
    return (
      <>
        {locale === 'en' ? (
          <LogoEnJSX className={styles.logo} />
        ) : (
          <LogoChiJSX className={styles.logo} />
        )}
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: getFieldStatic('bonuses_to_account_demo')
          }}
        />
        <div className={styles.text}>{getFieldStatic('continue_luck_demo')}</div>
        <div className={styles.textBlock}>
          <div className={styles.element}>
            <div className={styles.label}>{getFieldStatic('your_login')}</div>
            <div className={styles.textRow}>
              {String(data.login_id)}
              <CopyJSX onClick={copyToClipboard} />
            </div>
          </div>
          <div className={styles.element}>
            <div className={styles.label}>{getFieldStatic('your_password')}</div>
            <div className={styles.textRow}>
              {data.password}
              <CopyJSX onClick={copyToClipboard} />
            </div>
          </div>
        </div>
        <div className={styles.button}>
          {isMobile && copied && (
            <div className={styles.info}>
              <SuccessCheckJSX />
              <span>{getFieldStatic('password_copied')}</span>
            </div>
          )}
          <Button
            modifiers={['red']}
            onClick={() => onNextStep(2)}
            disabled={!copied}
            className={styles.buttonNext}>
            {' '}
            {getFieldStatic('next')}
          </Button>
        </div>
      </>
    )
  }

  const modalComponent = {
    0: isMobile ? (
      <div className={styles.mobileWrapper}>
        <div className={styles.cover}>
          <EqualElement />
        </div>
      </div>
    ) : (
      <Modal
        isOpen={isOpen}
        onClose={() => onNextStep(1)}
        disabledPortal
        backgroundOverlay={backgroundQuiz_5}
        modifers={modifers}
        backgroundClassName={styles.backgroundModal}
        disabledClickOutside>
        <div className={clsx(styles.wrapper, styles.two)}>
          <EqualElement />
        </div>
      </Modal>
    ),
    1: <EarnedDailyRewardModal quizStep={1} isOpen={isOpen} onClose={() => onNextStep(2)} />,
    2: <EarnedDailyRewardModal quizStep={2} isOpen={isOpen} onClose={() => onNextStep()} />
  }

  return <div className={styles.wrapper}>{modalComponent?.[step] || modalComponent[0]}</div>
}
