import { FC, useState } from 'react'

import { useBasketStore } from '@/app/zustand-store'

import { useTranslationField } from '@/shared/hooks'
import { Button } from '@/shared/ui/form'

import styles from './SettingsContent.module.scss'

export const SettingsContent: FC = () => {
  const { getFieldStatic } = useTranslationField()
  const { changeDecreaseStep, changeIncreaseStep, decreaseStep, increaseStep, changeIsSettings } =
    useBasketStore()
  const [rateIncrease, setRateIncrease] = useState(increaseStep)
  const [rateDecrease, setRateDecrease] = useState(decreaseStep)

  const saveChanges = () => {
    changeIncreaseStep(rateIncrease)
    changeDecreaseStep(rateDecrease)
    changeIsSettings(false)
  }

  const onSetDefault = () => {
    setRateIncrease(500)
    setRateDecrease(100)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.contentBlock}>
          <div className={styles.textBlock}>
            <div className={styles.label}>{getFieldStatic('rate_increase_step')}</div>
            <div className={styles.text}>{getFieldStatic('rate_increase_step_text')}</div>
          </div>
          <div className={styles.inputBlock}>
            <input
              inputMode='numeric'
              onChange={val => setRateIncrease(Number(val.target.value.replace(/\D+/g, '')))}
              value={rateIncrease}
              maxLength={7}
              style={{ width: `${String(rateIncrease).length * 9 + 44}px ` }}
            />
          </div>
        </div>
        <div className={styles.contentBlock}>
          <div className={styles.textBlock}>
            <div className={styles.label}>{getFieldStatic('rate_decrease_step')}</div>
            <div className={styles.text}>{getFieldStatic('rate_decrease_step_text')}</div>
          </div>
          <div className={styles.inputBlock}>
            <input
              inputMode='numeric'
              onChange={val => setRateDecrease(Number(val.target.value.replace(/\D+/g, '')))}
              value={rateDecrease}
              maxLength={7}
              style={{ width: `${String(rateDecrease).length * 9 + 44}px ` }}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.default} onClick={onSetDefault}>
          {getFieldStatic('basket_default_settings')}
        </div>
        <Button type='button' modifiers={['red']} onClick={saveChanges}>
          {getFieldStatic('save')}
        </Button>
      </div>
    </div>
  )
}
