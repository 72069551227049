import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'

import UserService from '@/shared/services/user.service'

import { IHistoryFiltersForm } from '@/shared/types/global.types'

const defaultPagination = { count: 10, page: 1 }

export const useBasketHistory = (historyFilters: IHistoryFiltersForm | null) => {
  const { ref, inView } = useInView({
    threshold: 0
  })

  const [pagination, setPagination] = useState(defaultPagination)

  //TODO возможно переписать на infiniteQuery
  const result = useQuery(
    ['get all tournaments', historyFilters],
    () =>
      UserService.getBetsList({
        ...historyFilters,
        ...pagination
      }),
    {
      select: ({ data }) => data,
      retry: 0,
      keepPreviousData: true
    }
  )

  useEffect(() => {
    if (inView) {
      setPagination({ count: pagination.count + 10, page: pagination.page })
      setTimeout(() => {
        result.refetch()
      }, 50)
    }
  }, [inView])

  return {
    ref,
    items: result?.data?.items,
    ...result
  }
}
