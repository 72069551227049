import Cookies from 'js-cookie'

import { QUIZ_WENT } from '@/shared/constants'
import { IUsers } from '@/shared/types/user.types'

export const saveTokensStorage = (data: IUsers.ITokens) => {
  Cookies.set('refreshToken', data.refresh)
  Cookies.set('accessToken', data.access)
  Cookies.set('quiz_went', QUIZ_WENT)
  localStorage.setItem('access', data.access)
  localStorage.setItem('refresh', data.refresh)
  localStorage.setItem('quiz_went', QUIZ_WENT)
}

export const removeTokensStorage = () => {
  Cookies.remove('refreshToken')
  Cookies.remove('accessToken')
  localStorage.removeItem('access')
  localStorage.removeItem('refresh')
}
