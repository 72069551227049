import { useBasketHistory } from '@/features/basket/api'

import { BettingCard } from '@/entities/profile'

import { Loader } from '@/shared/ui/loader'

import styles from '../HistoryContent.module.scss'

export const CalculatedHistory = () => {
  const { ref, items, isFetching } = useBasketHistory({})

  return (
    <>
      <div className={styles.wrapper}>
        <>
          {items?.map(el => <BettingCard data={el} key={el.id} isInBasket />)}
          <div ref={ref} />
          {isFetching && <Loader isMini />}
        </>
      </div>
    </>
  )
}
