import { FC } from 'react'

import clsx from 'clsx'

import { CopyJSX } from '@/shared/assets/common/svg'
import { useTranslationField } from '@/shared/hooks'
import { IUsers } from '@/shared/types/user.types'
import { dateFormatter } from '@/shared/utils'

import styles from './MoreInfo.module.scss'

interface IProps {
  data: IUsers.IPaymentHistoryItem
  isOpen?: boolean
}
export const MoreInfo: FC<IProps> = ({ data, isOpen }) => {
  const { getFieldStatic, getField, locale } = useTranslationField()

  return (
    <div className={clsx(styles.moreInfo, isOpen && styles.open)}>
      <div>
        <div className={styles.moreWrapper}>
          <div className={styles.row}>
            <div className={styles.columnName}>{getFieldStatic('id')}</div>
            <div className={styles.value}>№{data.id}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnName}>{getFieldStatic('section')}</div>
            <div className={styles.value}>{getField(data.item, 'name')}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnName}>{getFieldStatic('date')}</div>
            <div className={styles.value}>{dateFormatter(data.date!, locale)}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnName}>{getFieldStatic('product')}</div>
            <div className={styles.value}>{getFieldStatic(data.type)}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnName}>{getFieldStatic('price')}</div>
            <div className={styles.value}>{data.price} $</div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnName}>{getFieldStatic('description')}</div>
            <div className={styles.value}>{getField(data.item, 'description')}</div>
          </div>
          {data.item.promocode && (
            <div className={styles.row}>
              <div className={styles.columnName}>{getFieldStatic('promoCode')}</div>
              <div className={styles.copyWrap}>
                <div className={styles.value}>{data.item.promocode} </div>
                <span>
                  <CopyJSX />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
