import { FC } from 'react'

import clsx from 'clsx'

import { BasketBets } from '@/widgets/basket/basket.interface'
import { useBasket } from '@/widgets/basket/useBasket'

import { TrashBasketJSX } from '@/shared/assets/common/svg'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { timeFormatter } from '@/shared/utils'

import styles from './SingleBet.module.scss'

interface ISingleBet {
  bet: BasketBets.Basket
  betValue?: number
  historyBetValue?: number
  isSingle?: boolean
}

const SingleBet: FC<ISingleBet> = ({ bet, betValue, historyBetValue, isSingle }) => {
  const cart = useBasket()
  const { getField, getFieldStatic } = useTranslationField()
  const date = timeFormatter(bet.start_date)
  const { isMobile } = useIsMobile()

  const removeItemCart = () => {
    const newCart = cart.filter(
      item => item.main_const_sport_event_id !== bet.main_const_sport_event_id
    )
    localStorage.setItem('cart', JSON.stringify(newCart))
    window.dispatchEvent(new Event('storage'))
  }

  const potentialWinning = betValue ? (bet.market.market || 1) * betValue : undefined

  return (
    <div className={clsx(styles.wrapper, !isSingle && isMobile && styles.mobileNotSingle)}>
      <div className={styles.content}>
        <div className={styles.col}>
          <div className={styles.row}>
            <div className={styles.label}>{getField(bet.tournament, 'name')}</div>
            {!historyBetValue && <TrashBasketJSX onClick={removeItemCart} />}
          </div>
          <div className={styles.label}>{date}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.comands}>
            <div className={styles.comand}>{getField(bet, 'opponent_one_name')}</div>
            <div className={styles.comand}>{getField(bet, 'opponent_two_name')}</div>
          </div>
          <div className={styles.logos}>
            <img src={bet.opponent_two_image} alt='opponent' className={styles.logo} />
            <img src={bet.opponent_one_image} alt='opponent' className={styles.logo} />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.row}>
            <div className={styles.label}>{getFieldStatic('x')}</div>
            <div className={styles.value}>{bet.market.market}</div>
          </div>
          {historyBetValue && (
            <div className={styles.row}>
              <div className={styles.label}>{getFieldStatic('your_bet')}</div>
              <div className={styles.value}>{historyBetValue}</div>
            </div>
          )}
          <div className={styles.row}>
            <div className={styles.label}>{getFieldStatic('potential_winning')}</div>
            <div className={styles.value}>{(potentialWinning || 0).toFixed(2)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleBet
