export const timeFormatter = (date?: Date | string) => {
  if (!date) return null
  const dateToFormatting = typeof date === 'string' ? new Date(date) : date

  return dateToFormatting.toLocaleString('en-GB', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}
