import { FC, useState } from 'react'

import clsx from 'clsx'
import { Controller } from 'react-hook-form'

import { RightArrow, SelectArrowJSX } from '@/shared/assets/common/svg'
import { useTranslationField } from '@/shared/hooks'

import { Modal } from '../../modal'
import { TModalModifers } from '../../modal/modal.type'
import { ConnectForm, ConnectedRadioGroup } from '../form-connect'
import { IPropsSelect, MyOptionType } from '../form.interface'
import { RadioGroupDefault } from '../radio-group'

import styles from './SelectMobile.module.scss'

export const ReactSelectMobile: FC<IPropsSelect> = ({
  options,
  connect,
  name,
  containerClassName,
  registerConfig,
  placeholder,
  scroll,
  type,
  isBet
}) => {
  const { getFieldStatic } = useTranslationField()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const RadioComponent = connect ? ConnectedRadioGroup : RadioGroupDefault

  const openSelectModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const modifers = ['simple'] as TModalModifers[]

  const removeDuplicateCodes = (options: MyOptionType[]) => {
    let uniqueCodes: MyOptionType[] = []

    options.forEach(element => {
      if (!uniqueCodes.find(elem => elem.value === element.value)) {
        uniqueCodes.push(element)
      }
    })

    return uniqueCodes
  }

  const getModalTitle = () => {
    switch (type) {
      case 'country':
        return getFieldStatic('select_country')
      case 'phone':
        return getFieldStatic('select_phone_number')
      case 'radio':
        return ''
      default:
        return ''
    }
  }

  return (
    <ConnectForm>
      {({ control }) => (
        <Controller
          control={control}
          rules={registerConfig}
          name={name}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <div
                  className={clsx(
                    styles.select_control,
                    type === 'radio' && styles.select_control_radio,
                    containerClassName
                  )}
                  onClick={openSelectModal}
                  placeholder={placeholder}>
                  {isBet
                    ? options.find(el => el.value === value)?.label || placeholder
                    : getFieldStatic(value) || value || placeholder}
                  {type === 'radio' ? <RightArrow /> : <SelectArrowJSX />}
                </div>
                <Modal
                  title={getModalTitle()}
                  isOpen={isModalOpen}
                  onClose={closeModal}
                  className={clsx(styles.modal, type === 'radio' && styles.modalBottom)}
                  classNameTitle={styles.modalTitle}
                  disabledClickOutside
                  modifers={modifers}>
                  <div
                    className={clsx(
                      styles.modalList,
                      type !== 'radio' && styles.modalListNonRadio
                    )}>
                    <RadioComponent
                      classNameWrapper={clsx(styles.modalListElement)}
                      options={removeDuplicateCodes(options)}
                      name={name}
                      connect={connect}
                      onChange={onChange}
                      additionalAction={closeModal}
                      isMobileSelector
                      type={type}
                      scroll={scroll}
                    />
                  </div>
                  {type === 'radio' && (
                    <div className={clsx(styles.modalFooter)}>
                      <div onClick={closeModal}>{getFieldStatic('cancel')}</div>
                      <div onClick={closeModal}>{getFieldStatic('ok').toUpperCase()}</div>
                    </div>
                  )}
                </Modal>
              </>
            )
          }}
        />
      )}
    </ConnectForm>
  )
}
