import React, { FC, ReactElement } from 'react'

import clsx from 'clsx'
import { IoIosArrowForward } from 'react-icons/io'

import { useTranslationField } from '@/shared/hooks'

import styles from './tabItem.module.scss'

interface IProps {
  title: string
  onClick: () => void
  icon: ReactElement
  isActive?: boolean
  isLastBlock?: boolean
  isArrow?: boolean
  isCover?: boolean
}

export const TabItem: FC<IProps> = ({
  icon,
  onClick,
  title,
  isActive,
  isLastBlock,
  isCover,
  isArrow
}) => {
  const { getFieldStatic } = useTranslationField()
  return (
    <div
      className={clsx(
        styles.wrapper,
        isActive && styles.active,
        isLastBlock && styles.last,
        isCover && title === getFieldStatic('account-profile_route') && styles.covered
      )}
      onClick={onClick}>
      <div className={styles.icon}>{icon}</div>
      <span>{title}</span>
      {isArrow && (
        <div className={styles.arrow}>
          <IoIosArrowForward />
        </div>
      )}
    </div>
  )
}
