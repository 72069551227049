import { FC } from 'react'

import clsx from 'clsx'

import { IDepositPayment } from '@/features/profile/ui/deposit-list/deposit-list.interface'

import styles from './DepositCard.module.scss'

interface IProps {
  data: IDepositPayment
  onSelect?: (data: IDepositPayment) => void
  isActive?: boolean
}
export const DepositCard: FC<IProps> = ({ data, onSelect = () => {}, isActive }) => {
  return (
    <div
      className={clsx(styles.wrapperCard, isActive && styles.active)}
      onClick={() => onSelect(data)}>
      <div className={styles.image}>{data.img}</div>
      <div className={styles.titleBlock}>
        <span></span>
        <div className={styles.title}>{data.title}</div>
      </div>
    </div>
  )
}
